// @mui material components
import Container from "@mui/material/Container";
import React, { useState, useRef, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';


// Material Kit 2 React components
import MKBox from "components/MKBox";

import InternalConsistency from 'tabs/InternalConsistency';
import FactualAccuracy from 'tabs/FactualAccuracy';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
// Images
//import bgImage from "assets/images/bg-presentation.jpg";
import bgImage2 from "assets/images/bg-a-blurred.png";
import logoA from "assets/images/zencheck-logo-white.svg";
import logoOnly from "assets/images/ZenCheck-logo-only.png"
import whiteBg from "assets/images/white-bg.png"
import axios from 'axios';
import MKButton from "components/MKButton";
import { BiSolidAnalyse } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { Grid as Gridx } from 'react-loader-spinner'
import { TailSpin } from 'react-loader-spinner'
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import MKInput from "components/MKInput";
import Switch from "@mui/material/Switch";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import MKTypography from "components/MKTypography";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";


// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import { CiTrash } from "react-icons/ci";
import { FaTrash } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import FileUploadIcon from '@mui/icons-material/FileUpload';


import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GiLightningBranches } from "react-icons/gi";
import { MdFactCheck } from "react-icons/md";
import { GoVersions } from "react-icons/go";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaBookOpen } from "react-icons/fa";
import { FaSignOutAlt } from "react-icons/fa";
import { FaPercentage } from "react-icons/fa";
import { TbNumbers } from "react-icons/tb";
import InputAdornment from "@mui/material/InputAdornment";

// @mui icons
import SearchIcon from "@mui/icons-material/Search";
import { TiDelete } from "react-icons/ti";
import io from 'socket.io-client';
import { ClickAwayListener } from "@mui/base";
import ProgressBar from "@ramonak/react-progress-bar";
import Lottie from 'react-lottie';
import animationData from 'assets/loading-animation.json'; 
import Skeleton from '@mui/material/Skeleton';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Padding } from "@mui/icons-material";
import bgImageA from 'assets/background-blurred.json';
import mainLogo from 'assets/main-logo.json';
import localHostData from 'assets/default-localhost.json';
import posthog from 'posthog-js'
import FormControl from '@mui/material/FormControl';
const logo = mainLogo.mainLogo;
const bgImage = bgImageA.bgImage;

let socket = null;

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData, // the imported animation data
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice' // Supports scaling for responsive sizes
  }
};

function Presentation({ cachedPDFs, cachedBoundingBoxesData, signOut, loadedInitialData, processPDFCache, localThumbnailCache }) {
  const [activeTab, setActiveTab] = useState(0);
  const [isProcessed, setIsProcessed] = useState(false);
  const handleTabType = (event, newValue) => setActiveTab(newValue);
  const [loadedPdf, setLoadedPDF] = useState('');
  const [processingProgress, setProcessingProgress] = useState(0);
  const [processingProgressInternalConsistency, setProcessingProgressInternalConsistency] = useState(0);
  const [canOpenInternalConsistency, setCanOpenInternalConsistency] = useState(false);
  const [uploadingBtnText, setUploadingBtnText] = useState('New Analysis');
  const [currentlyProcessing, setCurrentlyProcessing] = useState(true);
  const [loadedBoundingBoxes, setLoadedBoundingBoxes] = useState([]);
  const [dataUpdaeVersion, setDataUpdaeVersion] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const hiddenFileInput = useRef(null);
  const [showGeneralMarginModal, setshowGeneralMarginModal] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [showErrorModal, setshowErrorModal] = useState(false);
  const [errorReason, setErrorReason] = useState(false);
  const [tempGeneralMargin, setTempGeneralMargin] = useState(0.2);
  const [tempPercentageMargin, setTempPercentageMargin] = useState(0.99);
  const [currentGeneralMargin, setCurrentGeneralMargin] = useState(0.2);
  const [currentPercentageMargin, setCurrentPercentageMargin] = useState(0.99);
  const [documentToDelete, setDocumentToDelete] = useState("");
  const [disableSettingsOptions, setDisableSettingsOptions] = useState(false);
  const [processingApproval, setProcessingApproval] = useState(false);
  const [docType, setDocType] = useState(0);
  //const [selectedUploadType, setSelectedUploadType] = React.useState('');
  const toggleGeneralMarginModal = () => setshowGeneralMarginModal(!showGeneralMarginModal);
  const toggleDeleteModal = () => { setDisableSettingsOptions(false); setshowDeleteModal(!showDeleteModal) };
  const toggleErrorModal = () => {
    if (showErrorModal) {
      setErrorReason("");
    }
     setshowErrorModal(!showErrorModal)
    }
  const [dropdown, setDropdown] = useState(null);
  const [dropup, setDropup] = useState(null);
  const [isLoadingFromDashboard, setIsLoadingFromDashboard] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [loadedComments, setLoadedComments] = useState([]);
  const [loadedVisitedHighlights, setLoadedVisitedHighlights] = useState([]);
  const [user, setUser] = useState(null);
  const [newChanges, setNewChanges] = useState(false);
  const [selecedAccount, setSelecedAccount] = useState(window.profileStatus && window.profileStatus.currentAccountSelected ? window.profileStatus.currentAccountSelected : "");
  const [loadedAccountData, setLoadedAccountData] = useState([]);

  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);

  const openDropup = ({ currentTarget }) => setDropup(currentTarget);
  const closeDropup = () => setDropup(null);
  const domain = window.location.hostname;
  if (domain === "localhost" ) localStorage.setItem('userData',JSON.stringify(localHostData))


  // Styles
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  const dropupIconStyles = {
    transform: dropup ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  useEffect(() => {
    if (localStorage.getItem('userData')) {
      const userData = domain !== "localhost" ? window.profileStatus?.profileData : JSON.parse(localStorage.getItem('userData'));
      setTempGeneralMargin(Number(userData.factualAccuracyGeneral));
      setCurrentGeneralMargin(Number(userData.factualAccuracyGeneral))
      setTempPercentageMargin(Number(userData.factualAccuracyPercentage));
      setCurrentPercentageMargin(Number(userData.factualAccuracyPercentage));
    }

    
    if (localStorage.getItem('uploadId')) {
      //localStorage.setItem('uploadId', "8b1e078e-0c32-4375-b8e4-0cc121c33ffe");
      //localStorage.setItem('uploadId', "7b7d6806-bc8b-406f-851c-fd7aaca95a1f");
      //setSelectedFile("file");
      //checkFileStatus();
      //console.log("YAY2");
      //setSelectedFile("file");
      //checkFileStatus();
    } else {
      //localStorage.setItem('uploadId', "8b1e078e-0c32-4375-b8e4-0cc121c33ffe");
      //localStorage.setItem('uploadId', "7b7d6806-bc8b-406f-851c-fd7aaca95a1f");
      //setSelectedFile("file");
      //checkFileStatus();
      //ddcf9c67-c102-48bd-8b2c-7959a49fc234
    }

  }, []);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const loadSaved = (dataId, isCurrentlyProcessing) => {
    if (localStorage.getItem("cancelled")) {
      localStorage.removeItem("cancelled");
    }
    setCurrentlyProcessing(true);
    localStorage.setItem('uploadId', dataId);
    setSelectedFile("file");

    if (isCurrentlyProcessing) {
      const userData = domain !== "localhost" ? window.profileStatus?.profileData : JSON.parse(localStorage.getItem('userData'));
      const item = userData.uploaded.find(dx => dx.id === dataId);
  if (userData && item) {
    setProcessingProgress(item.progress);
    if (item.completedFactualConsistency) {
      setProcessingProgressInternalConsistency(Number(item.progress).toFixed(2));
      setActiveTab(0);
      setCanOpenInternalConsistency(false);
      checkFileStatus(true);
    } else {
      
    }
    
  }
      newLiveConnection(dataId);
    } else {
      setIsLoadingFromDashboard(true);
      checkFileStatus();
    }

    setTimeout(() => posthog.capture('$pageview'), 1300);

    
    
  }

  const deleteDocument = () => {
    setDisableSettingsOptions(true);
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    if (window.profileStatus && window.profileStatus.csrfToken) {
      axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/remove-document' : '/alphax/remove-document', { userId, token, docId: documentToDelete.id }, {
        headers: {
          'X-CSRF-Token': window.profileStatus.csrfToken
        }})
        .then((response) => {
          console.log("Progress: ", response.data);
          if (response.data.ok) {
            const userData = response.data.data;
            if (!user)
              setUser({
                fullName: `${userData.firstName} ${userData.lastName}`,
                firstName: userData.firstName,
                lastName: userData.lastName,
                profileImage: userData.profileImage,
                initial: "",
                isAdmin: userData.isAdmin
              });
            userData.uploaded.reverse()
            if (window.profileStatus) {
              window.profileStatus.data = userData;
            window.profileStatus.profileData = userData;
            } else {
              window.profileStatus = userData;
            }
            window.profileStatus.profileData = userData;
            localStorage.setItem('userData', JSON.stringify(userData));
            setProfileData(userData);
            setshowDeleteModal(false);
            setTimeout(function () { setDisableSettingsOptions(false); }, 2000);
          }

        })
        .catch((error) => {
          console.log(error);
        });
    } else {

    }
  }


  const approveDocument = () => {
    setProcessingApproval(true);
    const userId = localStorage.getItem('userId');
    if (window.profileStatus && window.profileStatus.csrfToken) {
      axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/approve-document' : '/alphax/approve-document', { userId, docId: localStorage.getItem('uploadId') }, {
        headers: {
          'X-CSRF-Token': window.profileStatus.csrfToken
        }})
        .then((response) => {
          console.log("Progress: ", response.data);
          if (response.data.ok) {
            const userData = response.data.data;
            if (!user)
              setUser({
                fullName: `${userData.firstName} ${userData.lastName}`,
                firstName: userData.firstName,
                lastName: userData.lastName,
                profileImage: userData.profileImage,
                initial: "",
                isAdmin: userData.isAdmin
              });
            userData.uploaded.reverse()
            window.profileStatus.data = userData;
            window.profileStatus.profileData = userData;
            localStorage.setItem('userData', JSON.stringify(userData));
            setProfileData(userData);
            setProcessingApproval(false);
            resetUploaded()
          }

        })
        .catch((error) => {
          console.log(error);
        });
    } else {

    }
  }

  const cancelAnalysingDocument = () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const uploadId = localStorage.getItem('uploadId');
    if (userId && uploadId && window.profileStatus && window.profileStatus.csrfToken) {
      axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/cancel-analysing-document' : '/alphax/cancel-analysing-document', { userId, token, docId: uploadId }, {
        headers: {
          'X-CSRF-Token': window.profileStatus.csrfToken
        }})
        .then((response) => {
          console.log("Cancel response: ", response.data);
          if (response.data.ok) {
            localStorage.setItem("cancelled", "true");
            resetUploaded();
          }

        })
        .catch((error) => {
          console.log(error);
        });
    } else {

    }
  }

  const saveSettings = () => {
    setDisableSettingsOptions(true);
    const userId = localStorage.getItem('userId');
    if (window.profileStatus && window.profileStatus.csrfToken) {
      axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/save-settings' : '/alphax/save-settings', { userId, generalMargin: tempGeneralMargin, percentageMargin: tempPercentageMargin }, {
        headers: {
          'X-CSRF-Token': window.profileStatus.csrfToken
        }})
        .then((response) => {
          console.log("Progress: ", response.data);
          if (response.data.ok) {
            const userData = response.data.data;
            if (!user)
              setUser({
                fullName: `${userData.firstName} ${userData.lastName}`,
                firstName: userData.firstName,
                lastName: userData.lastName,
                profileImage: userData.profileImage,
                initial: "",
                isAdmin: userData.isAdmin
              });
            userData.uploaded.reverse()
            window.profileStatus.data = userData;
            window.profileStatus.profileData = userData;
            localStorage.setItem('userData', JSON.stringify(userData));
            setTempGeneralMargin(userData.factualAccuracyGeneral);
            setCurrentGeneralMargin(Number(userData.factualAccuracyGeneral))
            setTempPercentageMargin(Number(userData.factualAccuracyPercentage));
            setCurrentPercentageMargin(Number(userData.factualAccuracyPercentage));
          }
          setDisableSettingsOptions(false);
          setshowGeneralMarginModal(false);

        })
        .catch((error) => {
          console.log(error);
        });
    } else {

    }
  }

  const checkFileStatus = () => {
    const uploadId = localStorage.getItem('uploadId');
    const token = localStorage.getItem('token');
    const cancelled = localStorage.getItem("cancelled");
    console.log("uploadId: ", uploadId, currentlyProcessing, cancelled)
    if (uploadId && currentlyProcessing && !cancelled) {
      const domain = window.location.hostname;

      try {
        if (cachedBoundingBoxesData[uploadId]) {
          if (cachedBoundingBoxesData[uploadId].allData) {
            const userData = cachedBoundingBoxesData[uploadId].profileData;
                if (userData.uploaded) {
                  if (!user)
                    setUser({
                      fullName: `${userData.firstName} ${userData.lastName}`,
                      firstName: userData.firstName,
                      lastName: userData.lastName,
                      profileImage: userData.profileImage,
                      initial: "",
                      isAdmin: userData.isAdmin
                    });
                    if (window.profileStatus) {
                      window.profileStatus.data = userData;
                    window.profileStatus.profileData = userData;
                    } else {
                      window.profileStatus = userData;
                    }
                  localStorage.setItem('userData', JSON.stringify(userData));
                  console.log("response.data.allData: ", cachedBoundingBoxesData[uploadId].allData)
                  setLoadedBoundingBoxes(cachedBoundingBoxesData[uploadId].allData);
                  const comments = [];
                  if (cachedBoundingBoxesData[uploadId].comments) {
                    cachedBoundingBoxesData[uploadId].comments.forEach(element => {
                      comments.push({ ...element, date: new Date(element.date) })
                    });
                  }
                  setLoadedComments(comments);
                  setLoadedVisitedHighlights(cachedBoundingBoxesData[uploadId].visitedHighlights)
                  setIsLoadingFromDashboard(false);
                  setCurrentlyProcessing(false);
                  localStorage.removeItem("cancelled");
                } else {
                  signOut();
                }
          } else {
            signOut();
          }
          return;
        }
      } catch (e) {
        cachedBoundingBoxesData = {};
      }
      
      
      if (domain === "localhost") {
        axios.post('https://dev.zencheck.ai/alphax/status-x', { uploadId, token })
          .then((response) => {
            console.log("Progress: ", response.data);
            if (response.data.ok) {

              //localStorage.setItem('uploadId', response.data.uploadId);
              //setCurrentlyProcessing(true);
              //setSelectedFile(event.target.files[0]);
            }

            if (response.data.allData) {
              const userData = response.data.profileData;
              if (userData.uploaded) {
                if (!user)
                  setUser({
                    fullName: `${userData.firstName} ${userData.lastName}`,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    profileImage: userData.profileImage,
                    initial: "",
                    isAdmin: userData.isAdmin
                  });

                userData.uploaded.reverse();
                if (window.profileStatus) {
                  window.profileStatus.data = userData;
                window.profileStatus.profileData = userData;
                } else {
                  window.profileStatus = userData;
                }
                localStorage.setItem('userData', JSON.stringify(userData));
                setLoadedBoundingBoxes(response.data.allData);
                const comments = [];
                if (response.data.comments) {
                  response.data.comments.forEach(element => {
                    comments.push({ ...element, date: new Date(element.date) })
                  });
                }
                setLoadedComments(comments);
                setLoadedVisitedHighlights(response.data.visitedHighlights)
                setIsLoadingFromDashboard(false);
                setCurrentlyProcessing(false);
                localStorage.removeItem("cancelled");
              } else {
                signOut();
              }
            } else {
              signOut();
            }

          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              // Handle 403 Forbidden error
              console.error('Forbidden: Access to this resource is denied');
            } else {
              // Handle other errors
              console.error(error.message);
            }
          });
      } else {
        if (window.profileStatus && window.profileStatus.csrfToken) {
          const csrfToken = window.profileStatus.csrfToken;

          axios.post('/alphax/status', { uploadId, token }, {
            headers: {
              'X-CSRF-Token': csrfToken
            }})
            .then((response) => {
              console.log("Progress: ", response.data);
  
              if (response.data.allData) {
                const userData = response.data.profileData;
                if (userData.uploaded) {
                  if (!user)
                    setUser({
                      fullName: `${userData.firstName} ${userData.lastName}`,
                      firstName: userData.firstName,
                      lastName: userData.lastName,
                      profileImage: userData.profileImage,
                      initial: "",
                      isAdmin: userData.isAdmin
                    });
  
                  userData.uploaded.reverse();
                  if (window.profileStatus) {
                    window.profileStatus.data = userData;
                  window.profileStatus.profileData = userData;
                  } else {
                    window.profileStatus = userData;
                  }
                  localStorage.setItem('userData', JSON.stringify(userData));
                  console.log("response.data.allData: ", response.data.allData)
                  setLoadedBoundingBoxes(response.data.allData);
                  const comments = [];
                  if (response.data.comments) {
                    response.data.comments.forEach(element => {
                      comments.push({ ...element, date: new Date(element.date) })
                    });
                  }
                  setLoadedComments(comments);
                  setLoadedVisitedHighlights(response.data.visitedHighlights)
                  setIsLoadingFromDashboard(false);
                  setCurrentlyProcessing(false);
                  localStorage.removeItem("cancelled");
                } else {
                  signOut();
                }
              } else {
              }
  
            })
            .catch((error) => {
              if (error.response && error.response.status === 403) {
                // Handle 403 Forbidden error
                console.error('Forbidden: Access to this resource is denied');
              } else {
                // Handle other errors
                console.error(error.message);
              }
            });
        }
        
      }

    } else if (currentlyProcessing && !cancelled) {
      setTimeout(() => {
        checkFileStatus();
      }, 500);
    }

    if (cancelled) {
      localStorage.removeItem("cancelled");
    }
  }

  const resetUploaded = () => {
    /*reset*/
    setCurrentlyProcessing(true);
    localStorage.removeItem('uploadId')
    setLoadedBoundingBoxes([]);
    setLoadedComments([]);
    setLoadedVisitedHighlights([]);

    //setCurrentlyProcessing(false);
    setSelectedFile(null);
    setProcessingProgress(0.50);
    localStorage.removeItem("cancelled");
  }

  const newLiveConnection  = (uploadId, isAdmin = false) => {
    isAdmin = true; //remove this later today
    if (socket) {
      socket.disconnect();
      socket = null;
    }

    const domain = window.location.hostname;
    const SERVER_URL = domain === "localhost" ? "wss://dev.zencheck.ai" : `wss://${domain.replace("https://", "")}`;

    // Connect to Socket.IO server
    socket = io(SERVER_URL, {
      query: {
        uploadId
      }
    });

    socket.on("connection", async function () {
      console.log("here 3")
      console.log("connected!")
      socket.emit("sign-in", uploadId);
    });



    socket.on('progress', (data) => {
      console.log("processing progress: ", data);
      if (data.newVersion) setProcessingProgress(Number(data.progress).toFixed(2));

      if (data.completedFactualConsistency) {
        setActiveTab(0);
        setCanOpenInternalConsistency(false);
        checkFileStatus(true);
      } else if (data.error) {
        console.log("got error", data);
        if (data.errorType === "Password Protected") {
          setErrorReason("This is a password protected PDF. ZenCheck currently does not support password protected PDFs.")
        } else if(data.errorType === "Page Limit") {
          setErrorReason("The PDF you uploaded exceeds your page limit.")
        } else {
          setErrorReason("")
        }
        const userData = domain !== "localhost" ? window.profileStatus?.profileData : JSON.parse(localStorage.getItem('userData'));
        //const item = userData.uploaded.find(dx => dx.id === uploadId);
        userData.uploaded = userData.uploaded.filter(dx => dx.id !== uploadId);
        if (window.profileStatus) {
          window.profileStatus.data = userData;
        window.profileStatus.profileData = userData;
        } else {
          window.profileStatus = userData;
        }
        localStorage.setItem('userData', JSON.stringify(userData));
        toggleErrorModal(true);
      }
    });

    if (!isAdmin) setProcessingProgressInternalConsistency(100);

    socket.on('progress-stage-2', (data) => {
      console.log("processing progress: ", data);
      if (isAdmin && data.newVersion) setProcessingProgressInternalConsistency(Number(data.progress).toFixed(2));

      if (data.completedInternalConsistency) {
        setCanOpenInternalConsistency(true);

        //reload data
        const uploadId = localStorage.getItem('uploadId');
        const token = localStorage.getItem('token');
        const domain = window.location.hostname;
        if (domain === "localhost") {
          axios.post('https://dev.zencheck.ai/alphax/status-x', { uploadId, token })
            .then((response) => {
              console.log("Progress 2: ", response.data);
              setIsProcessed(true);

              if (response.data.allData) {
                const userData = response.data.profileData;
                if (userData.uploaded) {
                  userData.uploaded.reverse();
                  if (window.profileStatus) {
                    window.profileStatus.data = userData;
                  window.profileStatus.profileData = userData;
                  } else {
                    window.profileStatus = userData;
                  }
                  localStorage.setItem('userData', JSON.stringify(userData));
                  setUser({
                    fullName: `${userData.firstName} ${userData.lastName}`,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    profileImage: userData.profileImage,
                    initial: "",
                    isAdmin: userData.isAdmin
                  });
                  
                  if (isAdmin) {
                    console.log("response.data.allData: ", response.data.allData)
                    setLoadedBoundingBoxes([]);
                    setLoadedComments([]);
                    setLoadedVisitedHighlights([]);
                    setNewChanges(true);
                  setLoadedBoundingBoxes(response.data.allData);
                  
                  const comments = [];
                  if (response.data.comments) {
                    response.data.comments.forEach(element => {
                      comments.push({ ...element, date: new Date(element.date) })
                    });
                  }
                  setLoadedComments(comments);
                  setLoadedVisitedHighlights(response.data.visitedHighlights);
                  setIsLoadingFromDashboard(false);
                  localStorage.removeItem("cancelled");
                  setNewChanges(true);
                  }
                  setCurrentlyProcessing(false);
                  
                } else {
                  signOut();
                }

              }

            }).catch((error) => {
              if (error.response && error.response.status === 403) {
                // Handle 403 Forbidden error
                console.error('Forbidden: Access to this resource is denied');
              } else {
                // Handle other errors
                console.error(error.message);
              }
            });
        } else {

          if (window.profileStatus && window.profileStatus.csrfToken) {
          const csrfToken = window.profileStatus.csrfToken;

          axios.post('/alphax/status', { uploadId, token }, {
            headers: {
              'X-CSRF-Token': csrfToken
            }})
            .then((response) => {
                console.log("Progress 2: ", response.data);
                setIsProcessed(true);
  
                if (response.data.allData) {
                  const userData = response.data.profileData;
                  if (userData.uploaded) {
                    userData.uploaded.reverse();
                    if (window.profileStatus) {
                      window.profileStatus.data = userData;
                    window.profileStatus.profileData = userData;
                    } else {
                      window.profileStatus = userData;
                    }
                    localStorage.setItem('userData', JSON.stringify(userData));
                    if (!user)
                      setUser({
                        fullName: `${userData.firstName} ${userData.lastName}`,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        profileImage: userData.profileImage,
                        initial: "",
                        isAdmin: userData.isAdmin
                      });
                    
                    console.log("response.data.allData: ", response.data.allData)
                    if (isAdmin) {
                      setLoadedBoundingBoxes([]);
                      setLoadedComments([]);
                      setLoadedVisitedHighlights([]);
                      setNewChanges(true);
                      setLoadedBoundingBoxes(response.data.allData);
                    
                    const comments = [];
                    if (response.data.comments) {
                      response.data.comments.forEach(element => {
                        comments.push({ ...element, date: new Date(element.date) })
                      });
                    }
                    setLoadedComments(comments);
                    setLoadedVisitedHighlights(response.data.visitedHighlights)
                    setIsLoadingFromDashboard(false);
                    localStorage.removeItem("cancelled");
                    setNewChanges(true);
                    }
                    setCurrentlyProcessing(false);
                  } else {
                    signOut();
                  }
  
                }
  
              }).catch((error) => {
                if (error.response && error.response.status === 403) {
                  // Handle 403 Forbidden error
                  console.error('Forbidden: Access to this resource is denied');
                } else {
                  // Handle other errors
                  console.error(error.message);
                }
              });
        }
        
        }
      } else if (data.error) {
        console.log("got error")
        //toggleErrorModal(true);
      }
    });

    socket.on('progress-stage-3', (data) => {
      console.log("processing progress stage 3: ", data);
      setProcessingProgressInternalConsistency(Number(data.progress).toFixed(2));

      if (data.reloadData) {
        setCanOpenInternalConsistency(true);

        //reload data
        const uploadId = data.uploadId;
        const token = localStorage.getItem('token');
        const domain = window.location.hostname;
        if (domain === "localhost") {
          axios.post('https://dev.zencheck.ai/alphax/status-x', { uploadId, token })
            .then((response) => {
              console.log("Progress 2: ", response.data);
              setIsProcessed(true);

              if (response.data.allData) {
                const userData = response.data.profileData;
                if (userData.uploaded) {
                  userData.uploaded.reverse();
                  if (window.profileStatus) {
                    window.profileStatus.data = userData;
                  window.profileStatus.profileData = userData;
                  } else {
                    window.profileStatus = userData;
                  }
                  localStorage.setItem('userData', JSON.stringify(userData));
                  setUser({
                    fullName: `${userData.firstName} ${userData.lastName}`,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    profileImage: userData.profileImage,
                    initial: "",
                    isAdmin: userData.isAdmin
                  });
                  
                  if (isAdmin) {
                    setLoadedBoundingBoxes([]);
                    setLoadedComments([]);
                    setLoadedVisitedHighlights([]);
                    setNewChanges(true);
                    console.log("response.data.allData: ", response.data.allData)
                  setLoadedBoundingBoxes(response.data.allData);
                  
                  const comments = [];
                  if (response.data.comments) {
                    response.data.comments.forEach(element => {
                      comments.push({ ...element, date: new Date(element.date) })
                    });
                  }
                  setLoadedComments(comments);
                  setLoadedVisitedHighlights(response.data.visitedHighlights);
                  setIsLoadingFromDashboard(false);
                  localStorage.removeItem("cancelled");
                  setNewChanges(true);
                  }
                  setCurrentlyProcessing(false);
                  processPDFCache(domain === "localhost" ? `https://dev.zencheck.ai/alphax/${userData.uploaded[0].id}/pdf` : `/alphax/${userData.uploaded[0].id}/pdf`,userData.uploaded[0].id);
                  if (!isAdmin) setProcessingProgressInternalConsistency(100);
                  resetUploaded();
                  
                } else {
                  signOut();
                }

              }

            }).catch((error) => {
              if (error.response && error.response.status === 403) {
                // Handle 403 Forbidden error
                console.error('Forbidden: Access to this resource is denied');
              } else {
                // Handle other errors
                console.error(error.message);
              }
            });
        } else {

          if (window.profileStatus && window.profileStatus.csrfToken) {
            axios.post('/alphax/status', { uploadId, token }, {
              headers: {
                'X-CSRF-Token': window.profileStatus.csrfToken
              }
            })
              .then((response) => {
                console.log("Progress 2: ", response.data);
                setIsProcessed(true);
  
                if (response.data.allData) {
                  const userData = response.data.profileData;
                  if (userData.uploaded) {
                    userData.uploaded.reverse();
                    window.profileStatus.data = userData;
                    window.profileStatus.profileData = userData;
                    localStorage.setItem('userData', JSON.stringify(userData));
                    if (!user)
                      setUser({
                        fullName: `${userData.firstName} ${userData.lastName}`,
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        profileImage: userData.profileImage,
                        initial: "",
                        isAdmin: userData.isAdmin
                      });
                    
                    console.log("response.data.allData: ", response.data.allData)
                    if (isAdmin) {
                      setLoadedBoundingBoxes([]);
                      setLoadedComments([]);
                      setLoadedVisitedHighlights([]);
                      setNewChanges(true);
                      setLoadedBoundingBoxes(response.data.allData);
                    
                    const comments = [];
                    if (response.data.comments) {
                      response.data.comments.forEach(element => {
                        comments.push({ ...element, date: new Date(element.date) })
                      });
                    }
                    setLoadedComments(comments);
                    setLoadedVisitedHighlights(response.data.visitedHighlights)
                    setIsLoadingFromDashboard(false);
                    localStorage.removeItem("cancelled");
                    setNewChanges(true);
                    }
                    setCurrentlyProcessing(false);
                    setIsProcessed(true);
                    processPDFCache(domain === "localhost" ? `https://dev.zencheck.ai/alphax/${userData.uploaded[0].id}/pdf` : `/alphax/${userData.uploaded[0].id}/pdf`,userData.uploaded[0].id);
                    resetUploaded();
                  } else {
                    signOut();
                  }
  
                }
  
              }).catch((error) => {
                if (error.response && error.response.status === 403) {
                  // Handle 403 Forbidden error
                  console.error('Forbidden: Access to this resource is denied');
                } else {
                  // Handle other errors
                  console.error(error.message);
                }
              });
          }
          
        }
      } else if (data.error) {
        console.log("got error")
        //toggleErrorModal(true);
      }
    });
  }

  const handleFile = (fileUploaded) => {
    console.log("new upload")
    const isAdmin = true; //userData.isAdmin
    const formData = new FormData();
    formData.append('pdf', fileUploaded);
    formData.append('userId', localStorage.getItem('userId'));
    formData.append('docType',docType);
    resetUploaded();
    if (localStorage.getItem("cancelled")) {
      localStorage.removeItem("cancelled");
    }

    if (window.profileStatus && window.profileStatus.csrfToken) {
      axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/analyse' : '/alphax/analyse', formData, {
        headers: {
          'X-CSRF-Token': window.profileStatus.csrfToken
        },
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          const progress = (loaded / total) * 100;
          console.log(`Upload Progress: ${progress.toFixed(2)}%`);
          if (progress < 99.9) {
            setUploadingBtnText(`Uploading... (${progress.toFixed(2)}%)`);
          } else {
            setUploadingBtnText(`New Analysis`);
            const userData = domain !== "localhost" ? window.profileStatus?.profileData : JSON.parse(localStorage.getItem('userData'));
            userData.uploaded.unshift({
              "id": "uploading",
              "name": fileUploaded.name,
              "processedNumbers": 0,
              "internalInconsistencies": 0,
              "factualInconsistencies": 0,
              verified: 0,
              forReview: 0,
              numbersWithoutMatches: 0,
              currentlyProcessing: true,
              progress: 1,
              almostDoneProcessing: true
          });
  
          localStorage.setItem('userData',JSON.stringify(userData))
            if (isAdmin) {
              setCurrentlyProcessing(true);
              setSelectedFile(fileUploaded);
  
              setTimeout(() => {
                checkFileStatus();
              }, 500);
            }
            
          }
        }
      })
        .then((response) => {
          if (response.data.ok) {
            localStorage.setItem('uploadId', response.data.uploadId);
            const userData = domain !== "localhost" ? window.profileStatus?.profileData : JSON.parse(localStorage.getItem('userData'));
            const uploadingIndex = userData.uploaded.findIndex(item => item.id === "uploading");
  
            console.log({uploadingIndex})
            if (uploadingIndex !== -1) {
              userData.uploaded[uploadingIndex].id = response.data.uploadId;
              userData.uploaded[uploadingIndex].name = response.data.name;
            }
  
          localStorage.setItem('userData',JSON.stringify(userData))
   
             
            console.log("here 1")
  
            newLiveConnection(response.data.uploadId, userData.isAdmin);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    
  };

  const handleChange = event => {
    if (event.target.files.length) {
      const fileUploaded = event.target.files[0];
      event.target.value = null;
      handleFile(fileUploaded);
    }

  };

  const handleDropDownChange = (event) => {
    if (window.profileStatus && window.profileStatus.csrfToken) {
      axios.post('/alphax/admin-account-swich', { email: event.target.value }, {
        headers: {
          'X-CSRF-Token': window.profileStatus.csrfToken
        }})
          .then((response) => {
            if (response.data.ok) {
              setSelecedAccount(event.target.value);
              const selectedProfile = window.profileStatus.userAccounts.find(account => account.email === event.target.value);
              if (!selectedProfile.reversed) selectedProfile.uploaded.reverse();
              selectedProfile.reversed = true;
              setLoadedAccountData(selectedProfile);
            }

          })
          .catch((error) => {
            if (error.response && error.response.status === 403) {
              // Handle 403 Forbidden error
              console.error('Forbidden: Access to this resource is denied');
            } else {
              // Handle other errors
              console.error(error.message);
            }
          });
    }
    
    
  };


  const handleUploadDropdownChange = event => {
    if (event.target.value === "Investment Banking") {
      setDocType(1);
      handleClick();
    } else {
      setDocType(0);
      handleClick();
    }
  };

  const openDeleteModal = (data) => {
    setDocumentToDelete(data);
    setshowDeleteModal(true);
  }
  

  const handleCloseEvent = (event, reason) => {
    setIsProcessed(false)
  }

  const userData = domain !== "localhost" ? window.profileStatus?.profileData : JSON.parse(localStorage.getItem('userData'));

  let docName = "";

  if (userData && userData.uploaded && localStorage.getItem('uploadId')) {
    console.log("inn here")
    const item = userData.uploaded.find(dx => dx.id === localStorage.getItem('uploadId'));

    if (item) {
      docName = item.name.replace(/\.[^/.]+$/, "");
    }
  }
  document.title = docName === "" ? "ZenCheck" : `ZenCheck - ${docName}`;

  const filteredData = searchFilter === "" ? userData.uploaded.slice(0, 150) : userData.uploaded.filter(data => data.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1 || data.id === searchFilter).slice(0, 150);

  let fillEMptySPaces = filteredData.length ? new Array(10).fill(0) : []
//console.log("cachedPDFs[localStorage.getItem('uploadId')]: ", cachedPDFs[localStorage.getItem('uploadId')]);
//console.log("cachedPDFs",cachedPDFs)

const isAdmin = true; //userData.isAdmin





  return (
    <>
    <Snackbar
  open={isProcessed}
  autoHideDuration={5000}
  onClose={handleCloseEvent}
  message={"Internal Consistency Processing Completed"}
  action={() => {}}
/>
      <MKBox
        minHeight="7.5em"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          display: "grid",
          placeItems: "center",
        }}
      >
      </MKBox>
      <div id="header-buttons">
        <a className="logo-item" href="javascript:void(0)" onClick={resetUploaded}><img src={logo} /></a>
        <MKTypography variant="h5" color="light" fontWeight="bold" style={{ margin: "0px", marginTop: "0", color: "rgb(206, 212, 218)", fontWeight: "500" }}>
          {selectedFile === null ? "Dashboard" : docName}
        </MKTypography>
        <div id="header-button-container-right">

          {/*
          <MKTypography variant="h5" fontWeight="bold" style={{ margin: "0px", marginTop: "0" }}>
            <span><FaBookOpen /></span> <span style={{ position: "relative", top: "-4px", textTransform: "capitalize" }}>{docName}</span>
          </MKTypography> */ /*come here*/
          
          /*
          <Tooltip title="Please note that document uploads are limited to PDF files with a maximum of 20 pages." placement="right">
          </Tooltip>*/}


  
<MKButton className="analysis-dropdown" style={{ marginRight: "5px", padding: "0" }} size="small" variant="gradient" color="light" onClick={() => {}} disabled={domain === "localhost" || (!canOpenInternalConsistency && processingProgressInternalConsistency !== 0 && processingProgressInternalConsistency !== 100) || (selectedFile !== null && currentlyProcessing) ? true : false}>
  <Select
    id="upload-document-type-selector"
    value={"default"}
    label={<><FileUploadIcon style={{ marginRight: "3px" }} /> {uploadingBtnText}</>}
    onChange={handleUploadDropdownChange}
    style={{paddingLeft: "30px", paddingRight:"15px", height: "32px"}}
  >
    <MenuItem style={{display: "none"}} value={"default"}><FileUploadIcon style={{ position: "absolute", left: "0", paddingLeft: "30px" }} /> <span style={{marginLeft: "3px"}}>{uploadingBtnText}</span></MenuItem>
    <MenuItem value={"Due Diligence"}>Due Diligence</MenuItem>
    <MenuItem style={{display: "none"}} value={"Investment Banking"}>Investment Banking</MenuItem>
  </Select>
  </MKButton>
          {/*<MKButton style={{ marginRight: "5px" }} size="small" variant="gradient" color="light" onClick={handleClick} disabled={domain === "localhost" || (!canOpenInternalConsistency && processingProgressInternalConsistency !== 0 && processingProgressInternalConsistency !== 100) || (selectedFile !== null && currentlyProcessing) ? true : false}>
            <FileUploadIcon style={{ marginRight: "3px" }} /> {uploadingBtnText}
          </MKButton>*/}
          


          <MKButton size="small" variant="gradient" color="light" onClick={openDropdown}>
            <IoMdSettings sx={dropdownIconStyles} />
          </MKButton>
          <Menu anchorEl={dropdown} open={Boolean(dropdown)} onClose={closeDropdown}
            anchorOrigin={{
              vertical: 'bottom',  // Align the top of the menu with the bottom of the anchor element
              horizontal: 'left',  // Align the left of the menu with the left of the anchor element
            }}
            transformOrigin={{
              vertical: 'top',  // The menu grows downward from the top
              horizontal: 'left',  // The menu grows rightward from the left
            }}
            sx={{
              // Nudge the menu downwards (4px) and to the left (-0px)
              transform: 'translate(-4px, 4px)'
            }}
          >
            <ClickAwayListener onClickAway={closeDropdown}>
              <Paper sx={{ pt: 2, pb: 2, background: "#dce1e6" }} >
                <MenuItem onClick={() => { closeDropdown(); toggleGeneralMarginModal() }} sx={{ display: "flex", justifyContent: "space-between" }}>
                  {/* <p style={{width: "100%"}}> */}
                  {/* <TbNumbers />  */}
                  {/* <span style={{position: "relative", top: "-2px", marginRight: "20px"}}>Factual Accuracy Margin (General):</span> <b className="fam-menu-number">{currentGeneralMargin}</b></p> */}
                  <Typography>Allow differences up to:</Typography><Typography variant="body1" fontWeight="medium" color="darkBlue" >{currentGeneralMargin}</Typography>
                  {/* <b className="fam-menu-number">{currentGeneralMargin}</b> */}
                  {/* <Button variant="outlined">{currentGeneralMargin}</Button> */}
                </MenuItem>
                <MenuItem onClick={() => { closeDropdown(); toggleGeneralMarginModal() }} sx={{ display: "flex", justifyContent: "space-between" }} >
                  {/* <p style={{width: "100%"}}> */}
                  <Typography>Allow % differences up to:</Typography><Typography ml={2} variant="body1" fontWeight="medium" color="darkBlue" >{currentPercentageMargin}%</Typography>
                  {/* <FaPercentage />  */}
                  {/* <span style={{position: "relative", top: "-2px", marginRight: "20px"}}>Factual Accuracy Margin (%):</span> <b className="fam-menu-number">{currentPercentageMargin}%</b></p> */}
                </MenuItem>
                <MenuItem onClick={() => { closeDropdown(); signOut(); }}><p style={{ width: "100%" }}><FaSignOutAlt /> <span style={{ position: "relative", top: "-2px" }}> Sign Out</span></p></MenuItem>
              </Paper>
            </ClickAwayListener>
          </Menu>
        </div>









        <input
          type="file"
          onChange={handleChange}
          ref={hiddenFileInput}
          style={{ display: 'none' }} />
      </div>
      <Card
        sx={{
          p: 1,
          mx: { xs: 1, lg: 2 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { grey }, functions: { rgba } }) => rgba(grey[200], 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
        style={{ height: "calc(100vh/var(--zoom-level) - 65px)", marginBottom: "0" }}
      >
        {
          selectedFile === null ?
            <div style={{ marginTop: "0px", height: "100%" }}>
              {/* <MKTypography variant="h3" fontWeight="bold" style={{ marginBottom: "10px" }}>
                Dashboard
              </MKTypography> */}
              <div>
                <MKBox component="section" py={3}>
                  <Container>
                    <Grid container item xs={12} lg={4} py={1} mx="auto">
                      <MKInput
                        id="dashboard-search"
                        variant="standard"
                        placeholder="Search"
                        fullWidth
                        onChange={(e) => setSearchFilter(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {
                      window.profileStatus && window.profileStatus.isAdmin ?
                        <Grid container item xs={12} lg={4} py={1} mx="auto">
                          <Box sx={{ width: "100%" }}>
                            <FormControl fullWidth>
                              <InputLabel id="slected-account-label">Selected Account</InputLabel>
                              <Select
                                labelId="slected-account-label"
                                id="slected-account"
                                value={selecedAccount}
                                label="Selected Account"
                                onChange={handleDropDownChange}
                              >
                                {
                                  window.profileStatus && window.profileStatus.userAccounts && window.profileStatus.userAccounts.length ?
                                    window.profileStatus.userAccounts.map((data, idx) => (
                                      <MenuItem value={data.email}>{data.email}</MenuItem>
                                    ))
                                    :
                                    null
                                }
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        :
                        null
                    }

                  </Container>
                </MKBox>
              </div>
              {
                loadedAccountData && loadedAccountData.uploaded && loadedAccountData.uploaded.length ?
                <div className="four-or-more-items" style={{ overflow: "scroll", height: "calc(100% - 90px)", textAlign: "center" }}>
                {
                  loadedAccountData.uploaded.length ?
                  loadedAccountData.uploaded.map((data, idx) => (
                      <div className={ !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? "pdf-thumbnail disabled-thumbnail" :  "pdf-thumbnail"} style={{ width: "300px", opacity: data.almostDoneProcessing && !isAdmin ? "0.6" : "1" }}>
                        <div style={{position: "absolute", zIndex: "10", display: loadedInitialData ? "none" : "initial", left: "0"}}>
                        <Skeleton variant="rounded" width={300} height={200} />
                        <Box sx={{ pt: 0.5 }}>
                          <Skeleton />
                          <Skeleton width="40%" />
                          </Box>
                        </div>
                        {
                          !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ?
                          null
                          :
                          <a className="delete-doc" style={{display: !loadedInitialData ? "none" : "initial"}} href="javascript:void(0)" title="Remove Document" onClick={() => openDeleteModal(data)}><CiTrash /></a>
                        }
                        <div style={{transition: "opacity 0.5s", opacity: 1, height: "268px", width: "300px"}} className={!loadedInitialData ? "hidden" : ""}>
                        <CenteredBlogCard
                          image={localThumbnailCache[data.id] ? localThumbnailCache[data.id] : domain === "localhost" ? `https://dev.zencheck.ai/alphax/${data.id}/preview` : data.id === "uploading" ? whiteBg : `/alphax/${data.id}/preview`}
                          imgCallback={() => !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? {} : loadSaved(data.id)}
                          title={<a href="javascript:void(0)" className="item-title" style={{textAlign: "left"}} onClick={() => !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? {} : loadSaved(data.id, data.currentlyProcessing ? true : false)}>{data.name}</a>}
                          description={<a href="javascript:void(0)" style={{ color: "inherit", textAlign: "left" }} onClick={() => !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? {} : loadSaved(data.id, data.currentlyProcessing ? true : false)}> {data.currentlyProcessing && userData.isAdmin  ? <span>Processing <b>({data.progress ? data.progress.toFixed(0) : 0}%)</b></span> : data.currentlyProcessing || data.almostDoneProcessing ? <span>Processing...</span> :  <><span><b>{data.forReview !== undefined ? data.forReview : data.factualInconsistencies}</b> figures for review, of </span><b>{data.processedNumbers}</b></>}</a>}
                          action={{
                            type: "internal",
                            color: "secondary",
                            label: <p onClick={() => !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? {} : loadSaved(data.id, data.currentlyProcessing ? true : false)}>{data.currentlyProcessing ? <BiSolidAnalyse style={{ position: "relative", top: "1.3px" }} /> : <FaBookOpen style={{ position: "relative", top: "1.3px" }} />} {data.currentlyProcessing ? `Processing (${data.progress ? data.progress.toFixed(0) : 0}%)` : "View"}</p>,
                          }}
                        />
                        </div>
                        
                      </div>
                    ))
                    :
                    null
                }
                {
                  fillEMptySPaces.map((data, idx) => (
                    <div className="pdf-thumbnail" style={{ width: "300px" }}></div>
                  ))
                }
              </div>
              :
              <div className="four-or-more-items" style={{ overflow: "scroll", height: "calc(100% - 90px)", textAlign: "center" }}>
                {
                  filteredData.length ?
                    filteredData.map((data, idx) => (
                      <div className={ !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? "pdf-thumbnail disabled-thumbnail" :  "pdf-thumbnail"} style={{ width: "300px", opacity: data.almostDoneProcessing && !isAdmin ? "0.6" : "1" }}>
                        <div style={{position: "absolute", zIndex: "10", display: loadedInitialData ? "none" : "initial", left: "0"}}>
                        <Skeleton variant="rounded" width={300} height={200} />
                        <Box sx={{ pt: 0.5 }}>
                          <Skeleton />
                          <Skeleton width="40%" />
                          </Box>
                        </div>
                        {
                          !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ?
                          null
                          :
                          <a className="delete-doc" style={{display: !loadedInitialData ? "none" : "initial"}} href="javascript:void(0)" title="Remove Document" onClick={() => openDeleteModal(data)}><CiTrash /></a>
                        }
                        <div style={{transition: "opacity 0.5s", opacity: 1, height: "268px", width: "300px"}} className={!loadedInitialData ? "hidden" : ""}>
                        <CenteredBlogCard
                          image={localThumbnailCache[data.id] ? localThumbnailCache[data.id] : domain === "localhost" ? `https://dev.zencheck.ai/alphax/${data.id}/preview` : data.id === "uploading" ? whiteBg : `/alphax/${data.id}/preview`}
                          imgCallback={() => !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? {} : loadSaved(data.id)}
                          title={<a href="javascript:void(0)" className="item-title" style={{textAlign: "left"}} onClick={() => !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? {} : loadSaved(data.id, data.currentlyProcessing ? true : false)}>{data.name}</a>}
                          description={<a href="javascript:void(0)" style={{ color: "inherit", textAlign: "left" }} onClick={() => !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? {} : loadSaved(data.id, data.currentlyProcessing ? true : false)}> {data.currentlyProcessing && userData.isAdmin  ? <span>Processing <b>({data.progress ? data.progress.toFixed(0) : 0}%)</b></span> : data.currentlyProcessing || data.almostDoneProcessing ? <span>Processing...</span> :  <><span><b>{data.forReview !== undefined ? data.forReview : data.factualInconsistencies}</b> figures for review, of </span><b>{data.processedNumbers}</b></>}</a>}
                          action={{
                            type: "internal",
                            color: "secondary",
                            label: <p onClick={() => !isAdmin && (data.currentlyProcessing || data.almostDoneProcessing) ? {} : loadSaved(data.id, data.currentlyProcessing ? true : false)}>{data.currentlyProcessing ? <BiSolidAnalyse style={{ position: "relative", top: "1.3px" }} /> : <FaBookOpen style={{ position: "relative", top: "1.3px" }} />} {data.currentlyProcessing ? `Processing (${data.progress ? data.progress.toFixed(0) : 0}%)` : "View"}</p>,
                          }}
                        />
                        </div>
                        
                      </div>
                    ))
                    :
                    null
                }
                {
                  fillEMptySPaces.map((data, idx) => (
                    <div className="pdf-thumbnail" style={{ width: "300px" }}></div>
                  ))
                }
              </div>
              }
              



            </div>
            : currentlyProcessing ?
              <div id="processing-loading-parent">
                {
                  isLoadingFromDashboard ?
                  <Lottie options={defaultOptions} height={300} width={300} />
                  :
                  null
                /*<Gridx
                  visible={true}
                  height="150"
                  width="150"
                  color="#4fa94d"
                  ariaLabel="grid-loading"
                  radius="12.5"
                  wrapperStyle={{}}
                  wrapperClass="grid-wrapper"
                />*/}
                
                
                <h3 style={{ marginTop: "1em", fontWeight: "bold", marginBottom: "10px"}}>{isLoadingFromDashboard ? "" : "Analysing your file"}</h3>
                {
                  !isLoadingFromDashboard ?
                  <div style={{ marginLeft: "calc(50% - 163px)", width: "326px" }}>
                        <ProgressBar completed={processingProgress} isLabelVisible={false}/>
                      </div>
                      :
                      null
                }
                {
                  !isLoadingFromDashboard ?
                    <>
                      <h2 style={{fontWeight: "500", marginTop: "10px"}}>{Number(processingProgress).toFixed(0)}%</h2>
                      {
                        localStorage.getItem('uploadId') !== null ?
                          <div style={{ marginTop: "20px" }}>
                            <MKButton variant="gradient" color="white" onClick={cancelAnalysingDocument}>
                              Cancel
                            </MKButton>
                          </div>
                          :
                          null
                      }


                      <p style={{opacity: "0.3", position: "absolute", bottom: "100px", width: "100%", fontWeight: "bold"}}>A 20 page document takes 2 mins. You can close this page. <br/>
                      The analysed file will be in the Dashboard.
                      </p>


                    </>
                    :
                    null
                }


              </div>  
              :
              <Grid className="loaded-main-component" item xs={12} lg={3}>
                <AppBar position="static" >
                  {/* <div className="doc-title">
                    <MKTypography variant="h5" fontWeight="bold" style={{ margin: "0px", marginTop: "0" }}>
                      <span><FaBookOpen /></span> <span style={{ position: "relative", top: "-4px", textTransform: "capitalize" }}>{docName}</span>
                    </MKTypography> */}
                  {/* </div> */}
                  <Box sx={{ pb: 0, pl: 0, borderBottom: 0, borderColor: 'divider', marginBottom: "0.7em", display: "flex", justifyContent: "flex-start", boxShadow: "#848484 0 8px 6px -6px" }}>
                    <Tabs value={activeTab} onChange={handleTabType} >
                      <Tab label={<>
                        {/* <MdFactCheck /> */}
                        <span>Table correctness</span></>} />
                        {
                          !canOpenInternalConsistency && processingProgressInternalConsistency !== 0 && processingProgressInternalConsistency !== 100 ? 
                          <Tooltip title={!canOpenInternalConsistency && processingProgressInternalConsistency !== 0 && processingProgressInternalConsistency !== 100 ? "Processing..." : ""} placement="right">
                        <span>
                        <Tab style={{background: isProcessed ? "#00800030" : "initial", transition: "background 0.5s"}} disabled={!canOpenInternalConsistency && processingProgressInternalConsistency !== 0 && processingProgressInternalConsistency !== 100} className={!canOpenInternalConsistency && processingProgressInternalConsistency !== 0 && processingProgressInternalConsistency !== 100 ? "disabled-tab" : ""} label={<>
                        {/* <GiLightningBranches /> */}
                        <span> Internal consistency {processingProgressInternalConsistency !== 0 && Number(Number(processingProgressInternalConsistency).toFixed(0)) !== 100 ? "(" + Number(processingProgressInternalConsistency).toFixed(0) + "%)" : ""}</span></>} />
                        </span>
                        </Tooltip>
                        :
                        <Tab style={{background: isProcessed ? "#00800030" : "initial", transition: "background 0.5s"}} disabled={!canOpenInternalConsistency && processingProgressInternalConsistency !== 0 && processingProgressInternalConsistency !== 100} className={!canOpenInternalConsistency && processingProgressInternalConsistency !== 0 && processingProgressInternalConsistency !== 100 ? "disabled-tab" : ""} label={<>
                          {/* <GiLightningBranches /> */}
                          <span> Internal consistency {processingProgressInternalConsistency !== 0 && Number(Number(processingProgressInternalConsistency).toFixed(0)) !== 100 ? "(" + Number(processingProgressInternalConsistency).toFixed(0) + "%)" : ""}</span></>} />
                        }
                        
                        
                      
                      <Tab disabled={true} label={<>
                        {/* <GoVersions /> */}
                        
                        <span>Narrative correctness</span></>} />
                      <Tab disabled={true} label={<>
                        {/* <FaArrowTrendUp /> */}
                        <span>Unusual trends</span></>} />
                    </Tabs>
                    {
                      userData.email === "admin@zencheck.ai" ?
                      <MKButton style={{position: "absolute", right: "15px"}} variant="gradient" color="success" onClick={approveDocument}>
                      {processingApproval ? <TailSpin
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                /> : null} Approve
                    </MKButton>
                    :
                    null
                    }
                    
                  </Box>
                  {
                    <>
                      <div style={{ position: "relative" }}>
                        <div style={{ zIndex: activeTab === 1 ? "initial" : "-1", position: "absolute", width: "100%", opacity: activeTab === 1 ? 1 : 0 }}>
                          <InternalConsistency areas={loadedBoundingBoxes} loadedComments={loadedComments} loadedVisitedHighlights={loadedVisitedHighlights} user={user} firstPdf={domain === "localhost" ? (cachedPDFs[localStorage.getItem('uploadId')] ? cachedPDFs[localStorage.getItem('uploadId')] : `https://dev.zencheck.ai/alphax/${localStorage.getItem('uploadId')}/pdf`) : (cachedPDFs[localStorage.getItem('uploadId')] ? cachedPDFs[localStorage.getItem('uploadId')] :`/alphax/${localStorage.getItem('uploadId')}/pdf`)} secondPdf={domain === "localhost" ? `https://dev.zencheck.ai/alphax/${localStorage.getItem('uploadId')}/pdf` : `/alphax/${localStorage.getItem('uploadId')}/pdf`} docName={docName} newChanges={newChanges} setNewChanges={setNewChanges} />
                        </div>

                        <div style={{ zIndex: activeTab === 0 ? "initial" : "-1", position: "absolute", width: "100%", opacity: activeTab === 0 ? 1 : 0 }}>
                          <FactualAccuracy currentGeneralMargin={currentGeneralMargin} currentPercentageMargin={currentPercentageMargin} areas={loadedBoundingBoxes} firstPdf={domain === "localhost" ? (cachedPDFs[localStorage.getItem('uploadId')] ? cachedPDFs[localStorage.getItem('uploadId')] : `https://dev.zencheck.ai/alphax/${localStorage.getItem('uploadId')}/pdf`) : (cachedPDFs[localStorage.getItem('uploadId')] ? cachedPDFs[localStorage.getItem('uploadId')] :`/alphax/${localStorage.getItem('uploadId')}/pdf`)} secondPdf={domain === "localhost" ? `https://dev.zencheck.ai/alphax/${localStorage.getItem('uploadId')}/pdf` : `/alphax/${localStorage.getItem('uploadId')}/pdf`} docName={docName} canLoadInternalConsistency={!(!canOpenInternalConsistency && processingProgressInternalConsistency !== 0 && processingProgressInternalConsistency !== 100)} />
                        </div>

                        <div style={{ zIndex: activeTab !== 0 && activeTab !== 1 ? "initial" : "-1", position: "absolute", width: "100%", opacity: activeTab !== 0 && activeTab !== 1 ? 1 : 0 }}>Coming soon</div>
                      </div>

                    </>

                  }

                </AppBar>
              </Grid>
        }

      </Card>
      <Modal open={showGeneralMarginModal} onClose={toggleGeneralMarginModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={showGeneralMarginModal} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5"><img style={{ height: "20px", position: "relative", top: "2px" }} src={logoOnly} /> ZenCheck Settings</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleGeneralMarginModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox p={2}>
              <MKInput disabled={disableSettingsOptions} variant="standard" label="Factual Accuracy Margin (General)" type="number" value={tempGeneralMargin} onInput={(e) => setTempGeneralMargin(Number(e.target.value))} fullWidth />
            </MKBox>
            <MKBox p={2}>
              <MKInput disabled={disableSettingsOptions} variant="standard" label="Factual Accuracy Margin (%)" type="number" value={tempPercentageMargin} onInput={(e) => setTempPercentageMargin(Number(e.target.value))} fullWidth />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={toggleGeneralMarginModal}>
                close
              </MKButton>
              <MKButton disabled={disableSettingsOptions} onClick={saveSettings} variant="gradient" color="info">
                {disableSettingsOptions ? <TailSpin
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                /> : null} <span style={{ marginLeft: disableSettingsOptions ? "5px" : "initial" }}>save changes</span>
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

      <Modal open={showDeleteModal} onClose={toggleDeleteModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={showDeleteModal} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Remove Document</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleDeleteModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <p className="message-body">Are you sure you want to remove - <b>"{documentToDelete.name}"</b>?</p>
            <Divider sx={{ my: 0 }} />
            <MKBox display="flex" justifyContent="space-between" p={1.5}>
              <MKButton variant="gradient" color="dark" onClick={toggleDeleteModal}>
                close
              </MKButton>
              <MKButton disabled={disableSettingsOptions} onClick={deleteDocument} variant="gradient" color="error">
                {disableSettingsOptions ? <TailSpin
                  visible={true}
                  height="20"
                  width="20"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                /> : <FaTrash />} <span style={{ marginLeft: "5px" }}>Permanently Delete</span>
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

      <Modal open={showErrorModal} onClose={toggleErrorModal} sx={{ display: "grid", placeItems: "center" }}>
        <Slide direction="down" in={showErrorModal} timeout={500}>
          <MKBox
            position="relative"
            width="500px"
            display="flex"
            flexDirection="column"
            borderRadius="xl"
            bgColor="white"
            shadow="xl"
          >
            <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
              <MKTypography variant="h5">Error Occured</MKTypography>
              <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleErrorModal} />
            </MKBox>
            <Divider sx={{ my: 0 }} />
            <p className="message-body">An internal error occured while processing your document.
              {errorReason}</p>
            <Divider sx={{ my: 0 }} />
            <MKBox p={1.5}>
              <MKButton variant="gradient" color="dark" style={{ float: "right" }} onClick={() => { resetUploaded(); toggleErrorModal() }}>
                close
              </MKButton>
            </MKBox>
          </MKBox>
        </Slide>
      </Modal>

    </>
  );
}

export default Presentation;
//description={<><span><b>398</b> Numbers</span> <br></br> <span><b>0</b> Internal inconsistencies</span><br></br><span><b>0</b> Factual inconsistencies found</span></>}

//description={<><span><b>398</b> Numbers</span> • <span><b>0</b> Internal inconsistencies</span> • <span><b>0</b> Factual inconsistencies found</span></>}