// React imports
import React, { useState, useRef, useEffect } from 'react';

// React PDF Viewer imports
import { Worker, Viewer, RenderPageProps, useCurrentPage } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin, Trigger, RenderHighlightContentProps, RenderHighlightTargetProps } from '@react-pdf-viewer/highlight/lib/cjs/highlight';

import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { HighlightArea, RenderHighlightsProps, MessageIcon } from '@react-pdf-viewer/highlight';
import { Position, PrimaryButton, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { SetRenderRange, VisiblePagesRange } from '@react-pdf-viewer/core';
import { PageLayout } from '@react-pdf-viewer/core';

// React Resizable Panels imports
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import MuiPopper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

// Material UI imports
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Item from '@mui/material/Stack';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Card from "@mui/material/Card";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { TabPanel } from '@mui/lab';
import TabList from "@mui/lab/TabList";
import TabContext from '@mui/lab/TabContext';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import DeleteIcon from '@mui/icons-material/Delete';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import Fab from '@mui/material/Fab';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';


// Material UI Icons imports
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CommentIcon from '@mui/icons-material/Comment';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TagIcon from '@mui/icons-material/Tag';
import LinkIcon from '@mui/icons-material/Link';

// code editor imports
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/worker-json';


// Other imports
import { useHotkeys } from 'react-hotkeys-hook'
import { createPopper, VirtualElement } from '@popperjs/core';
import { IoMdClose } from "react-icons/io";
import { FaLess, FaLink, FaQuestion } from "react-icons/fa";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { testHighlights as _testHighlights, test_file } from "../test-highlights";
import FeedbackFab from 'components/ZencheckComponents/FeedbackFab';
import styledV2 from 'styled-components';
import { zoomPlugin } from '@react-pdf-viewer/zoom';


/*
.rpv-core__inner-pages {
    width: calc((100vw/2));
  }
  */
const StyledViewer = styledV2.div`
  .rpv-core__spinner {
  display: none;
  }

  .rpv-core__inner-page, .rpv-core__page-layer--single  {
  transition: width 0.5s, height 0.5s;
  }


`;

let tempTImoutPanel1 = {};
let tempTImout2Panel1 = {};
let srcDataLeftPanel = {};
let widthDataLeftPanel = {};
let oldSrcDataLeftPanel = {};
let firstLoadDoneLeftPanel = false;

let tempTImoutPanel2 = {};
let tempTImout2Panel2 = {};
let srcDataLeftPanel2 = {};
let widthDataLeftPanel2 = {};
let oldSrcDataLeftPanel2 = {};
let firstLoadDoneRightPanel = false;

let retry = false;

const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== 'arrow',
})(({ theme, arrow }) => ({
  zIndex: 1,
  '& > div': {
    position: 'relative',
  },
  '&[data-popper-placement*="bottom"]': {
    '& > div': {
      marginTop: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    '& > div': {
      marginBottom: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    '& > div': {
      marginLeft: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    '& > div': {
      marginRight: arrow ? 2 : 0,
    },
    '& .MuiPopper-arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
});

let ignoreCloseMenu = false;

// const FeedbackFab = () => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [message, setMessage] = useState('');

//   const handleClick = (event) => {
//     setAnchorEl(anchorEl ? null : event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     // setMessage('');
//   };

//   const handleSubmit = () => {
//     // Handle form submission logic here
//     console.log('Feedback submitted:', message);
//     setMessage('');
//     handleClose();
//   };

//   const handleInputChange = (event) => {
//     setMessage(event.target.value);
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter' && !event.shiftKey) {
//       event.preventDefault();
//       if (message.trim()) {
//         handleSubmit();
//       }
//     }
//   };

//   const open = Boolean(anchorEl);
//   const id = open ? 'simple-popper' : undefined;

//   return (
//     <>
//       <Tooltip title="Give feedback" placement="left">
//         <Fab 
//           color="default" 
//           aria-label="give feedback"
//           size="small" 
//           sx={{
//             position: 'fixed',
//             bottom: 16,
//             right: 16,
//           }}
//           onClick={handleClick}
//         >
//           <FeedbackOutlinedIcon />
//         </Fab>
//       </Tooltip>

//       <Popper id={id} open={open} anchorEl={anchorEl} placement="top-end" 
//       modifiers={[{ name: 'offset', options: { offset: [0, 8] } }]}
//     >
//         <Paper elevation={3} sx={{ padding: 1, width: 300 }}>
//           <Box display="flex" justifyContent="space-between" alignItems="center">
//             <Typography variant="h6">Feedback</Typography>
//             <Tooltip title="close" placement="top">
//             <IconButton
//               aria-label="close"
//               onClick={handleClose}
//               size="small"
//               sx={{
//                 position: 'relative',
//                 right: 0,
//                 top: 0,
//               }}
//             >
//               <CloseIcon />
//             </IconButton>
//             </Tooltip>
//           </Box>
//           <TextField
//             autoFocus
//             // margin="dense"
//             id="feedback"
//             // label="Leave your feedback"
//             type="text"
//             fullWidth
//             variant="outlined"
//             value={message}
//             onChange={handleInputChange}
//             onKeyPress={handleKeyPress}
//             multiline
//             maxRows={12}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end" sx={{ pr: 1 }}>
//                   <Tooltip title="Submit" placement="bottom">
//                     <IconButton
//                       aria-label="submit feedback"
//                       onClick={handleSubmit}
//                       disabled={!message.trim()}
//                       size="small"
//                     >
//                       <SendIcon size="small" />
//                     </IconButton>
//                   </Tooltip>
//                 </InputAdornment>
//               ),
//             }}
//             sx={{ mt: 1 }}
//           />
//         </Paper>
//       </Popper>
//     </>
//   );
// };

let alreadyLoaded1 = 0;
let alreadyLoaded2 = 0;
let useNewRenderFix = true;
let formated_highlights/*: Array<HighlightArea>*/ = [];

const CustomPageLayer = ({ renderPageProps }) => {
  React.useEffect(() => {
      // Mark the page as rendered completely when the text layer is rendered
      // So the next page in the document will be rendered
      if (renderPageProps.textLayerRendered) {
          renderPageProps.markRendered(renderPageProps.pageIndex);
      }
  }, [renderPageProps.textLayerRendered]);

  return (
      <>
          {renderPageProps.svgLayer.children}
          {renderPageProps.textLayer.children}
          {renderPageProps.annotationLayer.children}
      </>
  );
};

const InternalConsistency = ({ areas, loadedComments, loadedVisitedHighlights, firstPdf, secondPdf, docName, user, newChanges, setNewChanges }) => {

  const [highlightedItem, setHighlightedItem] = useState(-1);
  const [selectedUploaadId, setSelectedUploaadId] = useState("");
  const [isCommentMode, setIsCommentMode] = useState(false);
  const [isJSONMode, setIsJSONMode] = useState(false);
  const [comments, setComments] = useState([]) // loadedComments
  const [currentComment, setCurrentComment] = useState("");
  const [JSONs, setJSONs] = useState([]) // loadedComments
  const [currentJSON, setCurrentJSON] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [highlightIndex, setHighlightIndex] = useState(null)
  const [visitedHighlightIds, setVisitedHighlightIds] = useState(loadedVisitedHighlights);
  const [highPriorityHighlightIds, setHighPriorityHighlightIds] = useState([]);
  const [commentHighlightIds, setCommentHighlightIds] = useState([]);
  const [cycleStyle, setCycleStyle] = useState("all");
  const [interactionId, setInteractionId] = useState(1);
  const [isShiftPressed, setIsShiftPressed] = useState(false);

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [arrowRef, setArrowRef] = useState(null);

  const [isReferenceVisible, setIsReferenceVisible] = useState(true);

  const [currentPageLeftPanel, setCurrentPageLeftPanel] = useState(0);

  const [currentPageRightPanel, setCurrentPageRightPanel] = useState(0);

  // AI comment state
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [aiReasoning, setAiReasoning] = useState("");

  const [highlightStatus, setHighlightStatus] = useState(formated_highlights.map(highlight => ({
    id: highlight.id,
    tableIndex: highlight.tableIndex,
    color: highlight.color  // Initial color based on the category; adjust as needed
  })));

  const zoomPluginInstance = zoomPlugin();
    const { zoomTo, getZoom } = zoomPluginInstance;

    const viewerRef = useRef(null);



  if (formated_highlights.length !== highlightStatus.length
    || (formated_highlights.length && highlightStatus.length && formated_highlights[0].id !== highlightStatus[0].id)) {
    setHighlightStatus(formated_highlights.map(highlight => ({
      id: highlight.id,
      text: highlight.text,
      tableIndex: highlight.tableIndex,
      color: highlight.color  // Initial color based on the category; adjust as needed
    })))
  }


  const [expanded, setExpanded] = useState(false);

   // console.log("USER: ", user)


  const [tabValue, setTabValue] = useState("1");
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const initialSelectedItem = {
    id: "id",
    x: 0,
    y: 0,
    title: "title",
    text: 1,
    positionX: 0,
    positionY: 0,
    pageIndex: -1,
    referenceLinks: {
      reasoning: "reasoning",
      mathFormulaPy: "mathFormulaPy",
      errorType: "errorType",
      verified: false
    },
    references: [{
      id: 0,
      page: 4,
      value: 11
    }]
  };

  const [selectedItem, setSelectedItem] = useState({ ...initialSelectedItem });

  const domain = window.location.hostname;

  // const classes = useStyles();


  // Functionality to add notes. Some issues with the API so shelving until a later date. 

  //   const renderHighlightTarget = (props: RenderHighlightTargetProps) => {
  //     // Inline style for the wrapper div
  //     const wrapperStyle = {
  //       display: 'flex',
  //       position: 'absolute',
  //       left: `${props.selectionRegion.left}%`,
  //       top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
  //       transform: 'translate(0, 8px)',
  //       zIndex: 10000000000,
  //   };

  //   return (
  //       <div style={wrapperStyle}>
  //         <Paper elevation={3} >
  //                 <div className='pdf-pop-over' style={{ padding: '8px', paddingBottom: '8px', width: "100%", background: "#f3f3f37d" }}>
  //                     <div className='pop-over-icon-container'>
  //                     <IconButton sx={{ '&:hover': { background: "#0000000a" } }} aria-label="Add comment" onClick={props.toggle} ><AddCommentOutlinedIcon /></IconButton>
  //                   </div>
  //                 </div>
  //         </Paper>
  //       </div>
  //   );
  // };


  //   const renderHighlightContent = (props: RenderHighlightContentProps) => {

  //     const uniqueId = Date.now().toString();

  //     const handleAddHighlightCommentAction = () => {
  //       console.log('Add Highlight Comment clicked');
  //       setComments([...comments, { 
  //         interactionId: interactionId,
  //         interactionType: "comment",
  //         commentText: currentComment, 
  //         highlightDetails: {
  //           id: uniqueId,
  //           pageIndex: props.highlightAreas[0].pageIndex,
  //         }, 
  //         user: user, 
  //         date: new Date(),
  //         newlyCreatedArea: true,
  //         highlightId: uniqueId,
  //         highlightAreas: props.highlightAreas,
  //         highlightedText: props.selectedText,
  //       }]);
  //         setCurrentComment("");
  //         setInteractionId(interactionId + 1);
  //         props.cancel();
  //       };

  //     // Inline style for the wrapper div
  //     const wrapperStyle = {
  //       display: 'flex',
  //       position: 'absolute',
  //       left: `${props.selectionRegion.left}%`,
  //       top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
  //       transform: 'translate(0, 8px)',
  //       zIndex: 10000000000,
  //     };

  //     return (
  //       <div style={wrapperStyle}>
  //         <>
  //           <Paper elevation={3} >
  //             <Box p="8px">
  //               <MKInput
  //                 variant="outlined"
  //                 placeholder="Add comment..."
  //                 multiline
  //                 fullWidth
  //                 autoFocus
  //                 rows={4}
  //                 value={currentComment}
  //                 onChange={(e) => setCurrentComment(e.target.value)}
  //                 onKeyDown={commentInputKeyDownHandler}
  //                 onKeyPress={(e) => {
  //                   if (e.key === 'Enter' && !e.shiftKey) {
  //                     e.preventDefault();
  //                     handleAddHighlightCommentAction();
  //                   }
  //                 }}
  //               />
  //               <MKButton onClick={handleAddHighlightCommentAction} variant="outlined" color="secondary" sx={{ mt: 2 }}>Add</MKButton>
  //               <MKButton onClick={props.cancel} variant="outlined" color="secondary" sx={{ mt: 2 }}>Cancel</MKButton>
  //             </Box>
  //           </Paper>
  //         </>
  //       </div>
  //     )
  //   }


  // Refs for the highlight areas
  const highlightRefs = useRef({})

  // usage: render the first 20 pages initially
  const setRenderRange1: SetRenderRange = React.useCallback((visiblePagesRange: VisiblePagesRange) => {
    if (useNewRenderFix) {
      const startPage = 0; //Math.max(0, visiblePagesRange.startPage - 10);
    const endPage = visiblePagesRange.numPages; //visiblePagesRange.endPage + 10;

    //console.log("setRenderRange1 - Visible Pages Range:", visiblePagesRange);
    //console.log("setRenderRange1 - Calculated startPage:", startPage);
    //console.log("setRenderRange1 - Calculated endPage:", endPage);
    if (alreadyLoaded1 < 20) {
      alreadyLoaded1 += 1;
      return {
        startPage: startPage,
        endPage: endPage,
      };
    } else {
      return {
        startPage: startPage,
        endPage: endPage,
      };
    }
    } else {
      const startPage = Math.max(0, visiblePagesRange.startPage - 10);
    const endPage = visiblePagesRange.endPage + 10;

    console.log("setRenderRange1 - Visible Pages Range:", visiblePagesRange);
    console.log("setRenderRange1 - Calculated startPage:", startPage);
    console.log("setRenderRange1 - Calculated endPage:", endPage);

    return {
      startPage: startPage,
      endPage: endPage,
    };
    }
    
  }, []);

  const setRenderRange2: SetRenderRange = React.useCallback((visiblePagesRange: VisiblePagesRange) => {
    if (useNewRenderFix) {
      const startPage = 0; //visiblePagesRange.endPage <= 50 ? 0 : visiblePagesRange.startPage - 5;
    const endPage = visiblePagesRange.numPages;/*visiblePagesRange.startPage <= 50
      ? Math.max(50, visiblePagesRange.endPage + 5)
      : visiblePagesRange.endPage + 5;*/

    //console.log("setRenderRange2 - Visible Pages Range:", visiblePagesRange);
    //console.log("setRenderRange2 - Calculated startPage:", startPage);
    //console.log("setRenderRange2 - Calculated endPage:", endPage);

    if (alreadyLoaded2 < 20) {
      alreadyLoaded2 += 1;
      return {
        startPage: startPage,
        endPage: endPage,
      };
    } else {
      return {
        startPage: startPage,
        endPage: endPage,
      };
    }
    } else {
      const startPage = visiblePagesRange.endPage <= 50 ? 0 : visiblePagesRange.startPage - 5;
    const endPage = visiblePagesRange.startPage <= 50
      ? Math.max(50, visiblePagesRange.endPage + 5)
      : visiblePagesRange.endPage + 5;

    console.log("setRenderRange2 - Visible Pages Range:", visiblePagesRange);
    console.log("setRenderRange2 - Calculated startPage:", startPage);
    console.log("setRenderRange2 - Calculated endPage:", endPage);

    return {
      startPage: startPage,
      endPage: endPage,
    };
    }
  }, []);


  // Add 12px margin between pages
  const pageLayout1 = {
    buildPageStyles: ({ numPages, pageIndex, scrollMode, viewMode }) => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }),
    transformSize: ({ numPages, pageIndex, size }) => ({
      height: size.height + 12,
      width: size.width + 12,
    }),
  };

  const pageLayout2 = {
    buildPageStyles: ({ numPages, pageIndex, scrollMode, viewMode }) => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }),
    transformSize: ({ numPages, pageIndex, size }) => ({
      height: size.height + 12,
      width: size.width + 12,
    }),
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(slots) => {
          const {
            CurrentPageInput,
            Download,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            Print,
            ShowSearchPopover,
            SwitchTheme,
            Zoom,
            ZoomIn,
            ZoomOut,
          } = slots;
          return (
            <div className='toolbar' >
              <div className='toolbar-left' >
                <div style={{ padding: '0px 2px' }}><ShowSearchPopover /></div>
                <div style={{ padding: '0px 2px' }}><ZoomOut /></div>
                <div style={{ padding: '0px 2px' }}><Zoom /></div>
                <div style={{ padding: '0px 2px' }}><ZoomIn /></div>
              </div>
              <div className='toolbar-center' >
                <div style={{ padding: '0px 2px' }}><GoToPreviousPage /></div>
                <div style={{ padding: '0px 2px', marginRight: "12px", display: "flex", alignItems: "center", fontSize: "16px", background: "transparent", color: "#667085", minWidth: "fit-content" }}>
                  <CurrentPageInput style={{ width: "2.2REM", background: "transparent" }} /> of <NumberOfPages />
                </div>
                <div style={{ padding: '0px 2px' }}><GoToNextPage /></div>
              </div>
              <div className='toolbar-right' >
                <div className='embeded-doc-title'>
                  {/* {docName} */}
                </div>
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
    sidebarTabs: (defaultTabs) => {
      const allTabs = defaultTabs.concat({
        content: sidebarComments(),
        icon: <MessageIcon />,
        title: 'Comments',
      })
      return [
        // allTabs[3],
        allTabs[0],
      ]
    }
  },
  );

  const defaultLayoutPluginInstance2 = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>
        {(slots) => {
          const {
            CurrentPageInput,
            Download,
            GoToNextPage,
            GoToPreviousPage,
            NumberOfPages,
            Print,
            SwitchTheme,
            ShowSearchPopover,
            Zoom,
            ZoomIn,
            ZoomOut,
          } = slots;  
          return (
            <div className='toolbar' >
              <div className='toolbar-left' >
                <div style={{ padding: '0px 2px' }}><ShowSearchPopover /></div>
                <div style={{ padding: '0px 2px' }}><ZoomOut /></div>
                <div style={{ padding: '0px 2px' }}><Zoom /></div>
                <div style={{ padding: '0px 2px' }}><ZoomIn /></div>
              </div>
              <div className='toolbar-center' >
                <div style={{ padding: '0px 2px' }}><GoToPreviousPage /></div>
                <div style={{ padding: '0px 2px', marginRight: "12px", display: "flex", alignItems: "center", fontSize: "16px", background: "transparent", color: "#667085", minWidth: "fit-content" }}>
                  <CurrentPageInput style={{ width: "2.2REM", background: "transparent" }} /> of <NumberOfPages />
                </div>
                <div style={{ padding: '0px 2px' }}><GoToNextPage /></div>
              </div>
              <div className='toolbar-right' >
                <div className='embeded-doc-title'>
                  {/*docName*/}
                </div>
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
    sidebarTabs: (defaultTabs) => []
  });

  // Set the default tab on document load
  // const handleDocumentLoad = (e: DocumentLoadEvent) => {
  //   const { activateTab } = defaultLayoutPluginInstance;
  //   activateTab(0);
  // };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
  };


  if (selectedUploaadId !== localStorage.getItem('uploadId')) {
    formated_highlights = [];
    setSelectedUploaadId(localStorage.getItem('uploadId'));
  }

  function categoriseItem(item) {
    // Check if the item is verified first
    const isVerified = item.referenceLinks?.verified === true;

    if (isVerified) {
      return "relatedReferences";
    }

    // Check for no references or no match
    const hasNoReferencesOrNoMatch = !item.references || item.references.length === 0 || !item.references.some(ref => ref.match === true);

    if (hasNoReferencesOrNoMatch) {
      return "noRelatedReferences";
    }

    // If there are references and it's not verified, consider it as related
    return "relatedReferences";
  }

  if (formated_highlights.length === 0 || newChanges) {
    setNewChanges(false);
    formated_highlights = [];
    areas.filter(a =>
      a && ((a.isHeaderItem && a.isHeaderItemConfidence > 0.95) || !a.isHeaderItem || a.isHeaderItemConfidence < 0.95)
    ).forEach((item, index) => {

      const references = [];
      if (item.references) {
        item.references.forEach(element => {
          if (areas[element.id]) {
            if (areas[element.id].id !== item.id)
              references.push({
                ...areas[element.id],
                match: element.match,
                reason: element.reason
              });
          } else {
            console.error("An error occured finding element Id: ", element)
          }

        });
      }

      item.category = categoriseItem(item);

      // Check and update referenceLinks
      if (item.referenceLinks && Array.isArray(item.referenceLinks.aiReferenceObjects) && item.referenceLinks.aiReferenceObjects.length === 0) {
        item.referenceLinks.aiReferenceObjects.push({ 
          id: item.id,
          originalText: item.originalText,
          page_num: item.page_num
         });
      }

      formated_highlights.push({
        id: item.id,
        text: item.originalText,
        left: (item.left / 2800) * 100,
        top: (item.top / 2800) * 100,
        width: (item.width / 2800) * 100,
        height: (item.height / 2800) * 100,
        pageIndex: item.page_num - 1,
        title: item.title,
        tableIndex: item.tableIndex,
        references,
        referenceLinks: item.referenceLinks,
        color: (item.category === "noRelatedReferences") ? "orange" : "green",
        category: item.category
      });
    });
  }



  const setSelectedItemHandler = async (area) => {
    console.log("Selected area:", area);

    //jumpToHighlightArea1(area)

    const isChangingPages = area.pageIndex !== selectedItem.pageIndex;

    // Jump to the correct page first, if necessary
    if (isChangingPages /*|| !isInView(area)*/ || !isReferenceVisible) {
      console.log({isReferenceVisible, isChangingPages})
      ignoreCloseMenu = true;
      jumpToHighlightArea1(area);
      setTimeout(() => ignoreCloseMenu = false, 1000);
    }

    console.log("wait time: ", isChangingPages || !isReferenceVisible ? 500 : 0)

    // Wait a bit for the page to render, longer if changing pages
    //await new Promise(resolve => setTimeout(resolve, isChangingPages || !isInView(area) || !isReferenceVisible ? 500 : 0));

    //if (!isInView(area)) {
      //await new Promise(resolve => setTimeout(resolve, /*isChangingPages ||*/ !isInView(area) || !isReferenceVisible ? 10000 : 0));
    //}

    // Find the first valid reference by priority: matchTrueItem, aiReferenceItem, matchFalseItem
    const findFirstValidReference = () => {
      const matchTrueItem = area.references.find(ref => ref.match === true);
      // Ensure referenceLinks and aiReferenceObjects are defined before accessing them
      const aiReferenceItems = area.referenceLinks?.aiReferenceObjects ?? [];
      
      const aiReferenceItem = aiReferenceItems.length > 0 ? aiReferenceItems[0] : null;
      const matchFalseItem = area.references.find(ref => ref.match === false);
      console.log({aiReferenceItems, matchTrueItem, aiReferenceItem, matchFalseItem})

      return matchTrueItem || aiReferenceItem || matchFalseItem;
    };

    // Find the reference item by checking for hardcoded mappings or using standard reference logic
    const selectedReferenceItem = findFirstValidReference();

    if (selectedReferenceItem) {
      setHighlightedItem(selectedReferenceItem.id);
      // jumpToPage2(formated_highlights.find(highlightItem => highlightItem.id === selectedReferenceItem.id).pageIndex);
      console.log("highlighting: ",selectedReferenceItem, formated_highlights)
      jumpToHighlightArea2(formated_highlights.find(highlightItem => (selectedReferenceItem.idx !== undefined && selectedReferenceItem.idx === highlightItem.id) || (selectedReferenceItem.idx === undefined && selectedReferenceItem.id === highlightItem.id)));
    } else {
      console.log("No valid reference item found.");
      setHighlightedItem(-1);
    }

    // Additional handling to update UI elements based on the selected item
    console.log("Updating UI elements with area:", area, "and selectedReferenceItem:", selectedReferenceItem);

    updateUIElements(area, selectedReferenceItem);
  };

  const updateUIElements = (area, selectedReferenceItem) => {

    const selector = `.highlight-area[data-area-id="${area.id}"]`;
    const element = document.querySelector(selector);
    if (!element) {
      console.error("Element not found for area ID:", area.id);
      return;
    }
    console.log("Element found for area ID:", area.id);

    setAnchorEl(element);
    console.log("Anchor element set:", element);

    setSelectedIndex(0);
    // Set the item as selected
    setSelectedItem(area);
  };

  const isInView = (area) => {

    const selector = `.highlight-area[data-area-id="${area.id}"]`;
    const element = document.querySelector(selector);
    if (!element) {
      return false;
    } else {
      return true;
    }
  };




  useEffect(() => {
    console.log("useEffect triggered with anchorEl:", anchorEl, "and selectedItem:", selectedItem);
    // Check if selectedItem is valid and not just a reset to initial state
    if (anchorEl && !openMenu && selectedItem && selectedItem.id !== initialSelectedItem.id) {
      handleOpenMenu();
    }
  }, [selectedItem, openMenu, anchorEl]);

  const resetSelectedItem = () => {
    console.log("hide...")
    const selectedItemx = { ...initialSelectedItem };
    selectedItemx.pageIndex = -1;
    setSelectedItem(selectedItemx);
    setIsCommentMode(false);
  }

  const changeSelectedItem = (direction, cycleStyle) => {

    let currentIndex = highlightStatus.findIndex(item => item.id === selectedItem.id);

    let newIndex = currentIndex;
    const step = direction === 'next' ? 1 : -1;

    // Iterate over the highlightStatus to find the next/previous item based on color criteria
    for (let i = currentIndex + step; (direction === 'next' ? i < highlightStatus.length : i >= 0); i += step) {
      const highlight = highlightStatus[i];
      if (highlight.text !== "" && highlight.tableIndex === -1) {
        if (cycleStyle === "all" || highlight.color === cycleStyle) {
          newIndex = i;
          break;
        }
      }
      
    }

    if (newIndex !== currentIndex) {
      const newArea = formated_highlights.find(h => h.id === highlightStatus[newIndex].id);
      if (newArea) {
        console.log(`[changeSelectedItem] New selected area:`, newArea, highlightStatus[newIndex]);
        setSelectedItemHandler(newArea); // Apply the necessary DOM interactions
      } else {
        console.log(`[changeSelectedItem] No valid area found for ID: ${highlightStatus[newIndex].id} | length: ${formated_highlights.length}`);
      }
    } else {
      console.log(`[changeSelectedItem] No valid ${direction} item found. Staying at current index.`);
    }
  };


  // Hotkey for 'right' - Move to next item
  useHotkeys('right, tab', () => {
    if (openMenu) { // Ensure hotkey only has effect when menu is open
      changeSelectedItem('next', cycleStyle);
    }
  }, [selectedItem, selectedIndex, openMenu, cycleStyle, highlightedItem]); // Dependency array 

  // Hotkey for 'left' - Move to previous item
  useHotkeys('left', () => {
    if (openMenu) { // Ensure hotkey only has effect when menu is open
      changeSelectedItem('prev', cycleStyle);
    }
  }, [selectedItem, selectedIndex, openMenu, cycleStyle, highlightedItem]); // Dependency array 

  const updateHighlightColor = (id, newColor) => {
    setHighlightStatus(prevStatus => prevStatus.map(item =>
      item.id === id ? { ...item, color: newColor } : item
    ));
  };


  const saveComments = (comments, uploadId) => {
    axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/save-comments' : '/alphax/save-comments', { comments, uploadId })
      .then((response) => {
        if (!response.data.ok) {
          //show modal
          console.log("Error saving comments")
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }

  const saveVisited = (visitedHighlights, uploadId) => {
    axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/save-visited' : '/alphax/save-visited', { visitedHighlights, uploadId })
      .then((response) => {
        if (!response.data.ok) {
          //show modal
          console.log("Error saving visited highlight")
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }



  const handleOpenMenu = () => {
    setOpenMenu(true);
    setIsCommentMode(false);
    setCurrentComment("");
    setSelectedIndex(0);
  };

  const handleChecked = () => {
    const latestComments = [...comments, {
      interactionId: interactionId,
      interactionType: "checked",
      commentText: "✔",
      highlightDetails: selectedItem,
      user: user,
      date: new Date(),
      newlyCreatedArea: false,
      highlightId: selectedItem.id,
      highlightAreas: null,
      highlightedText: selectedItem.text,
    }]
    setComments(latestComments);
    saveComments(latestComments, localStorage.getItem('uploadId'))
    updateHighlightColor(selectedItem.id, "green");
    resetSelectedItem();
    changeSelectedItem("next", cycleStyle);
    setIsCommentMode(false);
    setInteractionId(interactionId + 1);
  };

  const handleReview = () => {
    // setComments([...comments, { text: "Please review", highlightDetails: selectedItem }]);
    if (!highPriorityHighlightIds.includes(selectedItem.id)) {
      setHighPriorityHighlightIds([...highPriorityHighlightIds, selectedItem.id]);
      console.log("High priority highlight IDs: ", highPriorityHighlightIds);
    }
    const latestComments = [...comments, {
      interactionId: interactionId,
      interactionType: "highPriority",
      commentText: "!",
      highlightDetails: selectedItem,
      user: user,
      date: new Date(),
      newlyCreatedArea: false,
      highlightId: selectedItem.id,
      highlightAreas: null,
      highlightedText: selectedItem.text,
    }];
    setComments(latestComments);
    saveComments(latestComments, localStorage.getItem('uploadId'));
    updateHighlightColor(selectedItem.id, "orange");
    resetSelectedItem();
    changeSelectedItem("next", cycleStyle);
    // activateTab(0);
    setIsCommentMode(false);
    setInteractionId(interactionId + 1);
  };

  const handleAddComment = (event) => {
    event.preventDefault(); // This prevents the "c" from being inputted
    setIsCommentMode(!isCommentMode);
  };

  const handleJSONButton = (event) => {
    event.preventDefault(); // This prevents the "c" from being inputted
    setIsJSONMode(!isJSONMode);
    setIsCommentMode(false);
  };

  const handleAddJSONAction = (event) => {
    const latestJSONs = [...JSONs, {
      revisedJSON: currentJSON,
    }];
    axios.post(domain === "localhost" ? 'https://dev.zencheck.ai/alphax/update-document-data' : '/alphax/update-document-data', { docId: localStorage.getItem('uploadId'), latestJSONs })
      .then((response) => {
        if (!response.data.ok) {
          //show modal
          console.log("Error saving changes")

          
        } else {
          resetSelectedItem();
          // activateTab(0);
          setIsCommentMode(false);
          setInteractionId(interactionId + 1);
        }

      })
      .catch((error) => {
        console.log(error);
      });

    console.log({latestJSONs})

    
  };

  const handleAddCommentAction = () => {
    const latestComments = [...comments, {
      interactionId: interactionId,
      interactionType: "comment",
      commentText: currentComment,
      highlightDetails: selectedItem,
      user: user,
      date: new Date(),
      newlyCreatedArea: false,
      highlightId: selectedItem.id,
      highlightAreas: null,
      highlightedText: selectedItem.text,
    }];
    setComments(latestComments);
    saveComments(latestComments, localStorage.getItem('uploadId'));
    if (!commentHighlightIds.includes(selectedItem.id)) {
      setCommentHighlightIds([...commentHighlightIds, selectedItem.id]);
      console.log("Comment highlight IDs: ", commentHighlightIds);
    }
    updateHighlightColor(selectedItem.id, "orange");
    setCurrentComment("");
    resetSelectedItem();
    changeSelectedItem("next", cycleStyle);
    // activateTab(0);
    setIsCommentMode(false);
    setInteractionId(interactionId + 1);
  };

  const handleAddAiCommentAction = () => {

    console.log("Handling add comment:", aiReasoning); // Check the current state of aiReasoning

    const latestComments = [...comments, {
      interactionId: interactionId,
      interactionType: "comment",
      commentText: aiReasoning,
      highlightDetails: selectedItem,
      user: user,
      date: new Date(),
      newlyCreatedArea: false,
      highlightId: selectedItem.id,
      highlightAreas: null,
      highlightedText: selectedItem.text,
    }];

    console.log("New comments array:", latestComments); // Check what is being added

    setComments(latestComments);
    saveComments(latestComments, localStorage.getItem('uploadId'));
    if (!commentHighlightIds.includes(selectedItem.id)) {
      setCommentHighlightIds([...commentHighlightIds, selectedItem.id]);
      console.log("Comment highlight IDs: ", commentHighlightIds);
    }
    updateHighlightColor(selectedItem.id, "orange");
    setCurrentComment("");
    setAiReasoning("");
    resetSelectedItem();
    changeSelectedItem("next", cycleStyle);
    setIsCommentMode(false);
    setInteractionId(interactionId + 1);
  };


  const handleCloseMenu = () => {
    console.log("handleCloseMenu called");
    setOpenMenu(false);
    resetSelectedItem();
    setCurrentComment("");
    setIsJSONMode(false);
  };

  useEffect(() => {
    console.log("openMenu after setOpenMenu:", openMenu);
  }, [openMenu]);

  const commentInputKeyDownHandler = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      console.log("Esc pressed within input");
      handleEscape();
    }
  };

  const handleEscape = () => {
    console.log(`Esc pressed. isCommentMode: ${isCommentMode}, openMenu: ${openMenu}`);

    if (isCommentMode) {
      console.log("Exiting comment mode...");
      setIsCommentMode(false);
      setCurrentComment("");
    } else if (openMenu) {
      console.log("Closing menu...");
      handleCloseMenu()
    }
  };

  useHotkeys('y,enter,space', handleChecked, { enabled: openMenu });
  useHotkeys('x', handleReview, { enabled: openMenu });
  useHotkeys('c', (event) => handleAddComment(event), { enabled: openMenu });
  useHotkeys('esc', handleEscape);

  function formatDate(date) {
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInSeconds / 3600);
    const diffInDays = Math.floor(diffInSeconds / 86400);

    // Format hour:minute AM/PM
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' + minutes : minutes} ${hours < 12 ? 'AM' : 'PM'}`;

    if (diffInSeconds < 60) return "Just now";
    if (diffInMinutes === 1) return "1 minute ago";
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
    if (diffInHours < 24) {
      const today = now.toDateString() === date.toDateString();
      const yesterday = new Date(now.setDate(now.getDate() - 1)).toDateString() === date.toDateString();

      if (today) return `Today at ${formattedTime}`;
      if (yesterday) return `Yesterday at ${formattedTime}`;
      return `${diffInHours} hours ago`;
    }
    if (diffInDays < 7) return `${diffInDays} days ago`;

    return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
  }


  const deleteHandler = (interactionId) => {
    const updatedComments = comments.filter(comment => comment.interactionId !== interactionId);
    setComments(updatedComments);
  };

  const renderComments = (comments, selectedItem) => {
    // Filter and sort comments
    const relevantComments = comments
      .filter(comment => comment.highlightDetails.id === selectedItem.id)
      .sort((a, b) => a.date - b.date);

    return (
      <>
        <List sx={{ width: '100%' }}>
          {relevantComments.length > 0 && !isCommentMode &&
            <Divider component="li" textAlign="center" >
              <Chip label="Comments" size="small"
                sx={{
                  '.MuiChip-label': {
                    fontSize: '0.75rem',
                    fontWeight: 300
                  }
                }}
              />
            </Divider>
          }
          <Box sx={{ maxHeight: '22em', overflow: 'auto', }}>

            {relevantComments.map((comment, index) => (
              < React.Fragment key={index} >
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    {
                      comment.user.profileImage ?
                        <Avatar alt={comment.user.fullName} src={comment.user.profileImage} />
                        :
                        <Avatar alt={comment.user.fullName} />
                    }

                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          component="p"
                          variant="body2"
                          fontWeight="medium"
                          display="inline"
                          sx={{ marginRight: '8px' }}
                        >
                          {comment.user.fullName}
                        </Typography>
                        <Typography
                          component="caption"
                          variant="caption"
                          color="text.secondary"
                          display="inline"
                        >
                          {formatDate(comment.date)}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <Typography
                        component="span" // Using span here to keep the Typography inline
                        variant="body2"
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 3,
                          textOverflow: 'ellipsis',
                          whiteSpace: 'normal' // Ensure normal whitespace handling inside the box
                        }}
                      >
                        {comment.commentText}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" sx={{ color: "rgba(0,0,0,0.2)" }}
                      onClick={() => deleteHandler(comment.interactionId)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {index < relevantComments.length - 1}
              </React.Fragment>
            ))}
          </Box>
        </List>
      </>
    );
  };

  useHotkeys('h', () => setIsShiftPressed(true), { keydown: true });
  useHotkeys('h', () => setIsShiftPressed(false), { keyup: true });

  useEffect(() => {
    let reason = null;
    if (selectedItem && selectedItem.referenceLinks)
      reason = selectedItem.referenceLinks.reasoning ? selectedItem.referenceLinks.reasoning : selectedItem.referenceLinks.reason ? selectedItem.referenceLinks.reason : null;
    if (selectedItem && selectedItem.referenceLinks && reason) {
      setAiReasoning(reason);
    } else {
      // Set a default or handle the absence of reasoning in some other way
      setAiReasoning("Default reasoning not available");
    }
  }, [selectedItem]);

  useEffect(() => {
    // Find the JSON object in formated_highlights with id === selectedItem.id
    const matchedItem = formated_highlights.find(item => item.id === selectedItem.id);
    
    // Check if a matched item was found
    if (matchedItem) {
      // Set the matched JSON object to the current state as a string
      setCurrentJSON(JSON.stringify(matchedItem, null, 2));
    } else {
      // Handle the case where no matched item was found (optional)
      setCurrentJSON('{}');
    }
  }, [selectedItem, formated_highlights]);
  



  const formatNumberforMenuHeader = (input) => {
    if (input === null || input === undefined) {
      return '';
    }

    let inputStr = input.toString();

    // Normalize the input: Remove all characters except digits, decimal points, parentheses, percentage, 'x', hyphens, and commas
    let cleanedInput = inputStr.replace(/[^\d,.()%x-]/g, '');

    // Correct handling of trailing commas and isolated commas
    // Replace commas that are not followed by three digits and are not part of a proper numeric pattern
    cleanedInput = cleanedInput.replace(/,(?!\d{3}(\D|$))/g, '');

    // Handle negative numbers formatted with parentheses
    if (cleanedInput.startsWith('(') && cleanedInput.endsWith(')')) {
      cleanedInput = '-' + cleanedInput.slice(1, -1).replace(/[()]/g, '');
    }

    // Remove any stray parentheses remaining
    cleanedInput = cleanedInput.replace(/[()]/g, '');

    // Detect and preserve any valid suffix like 'pp' or 'x'
    const suffix = cleanedInput.match(/(pp|x)$/);
    cleanedInput = cleanedInput.replace(/(pp|x)$/, ''); // Remove the suffix temporarily

    // Remove any trailing period from the cleaned input
    cleanedInput = cleanedInput.replace(/\.$/, '');

    return suffix ? cleanedInput + suffix[0] : cleanedInput;
  }

  const cleanText = (originalext) => {
    // Remove trailing commas and full stops
    let cleanedNumber = originalext.replace(/[,.]+$/, '');

    // Check for unmatched brackets and remove them
    if ((cleanedNumber.startsWith('(') && !cleanedNumber.endsWith(')')) ||
        (!cleanedNumber.startsWith('(') && cleanedNumber.endsWith(')'))) {
        cleanedNumber = cleanedNumber.replace(/[()]/g, '');
    }

    // Remove any remaining unwanted non-numeric characters at the end, except for important ones like %
    cleanedNumber = cleanedNumber.replace(/[^0-9%]+$/, '');

    return cleanedNumber;
  }


  // const renderMenuOLD = () => {

  //   if (!openMenu || selectedItem.pageIndex === -1) return null;

  //   return (
  //     <Popper
  //       key={selectedItem.id}
  //       open={openMenu}
  //       anchorEl={anchorEl}
  //       placement="bottom-start"
  //       disablePortal={true}
  //       transition
  //       style={{
  //         zIndex: 2,
  //         visibility: isShiftPressed ? 'hidden' : 'visible' // Hide the Popper when Shift key is pressed
  //       }}
  //       modifiers={[
  //         {
  //           name: 'flip',
  //           enabled: true,
  //           options: {
  //             altBoundary: true,
  //             rootBoundary: 'viewport',
  //             padding: 20,
  //           },
  //         },
  //         {
  //           name: 'preventOverflow',
  //           enabled: true,
  //           options: {
  //             altAxis: true,
  //             altBoundary: true,
  //             tether: true,
  //             rootBoundary: 'viewport',
  //             padding: 20,
  //           },
  //         },
  //         {
  //           name: 'arrow',
  //           enabled: true,
  //           options: {
  //             element: arrowRef,
  //           },
  //         },
  //       ]}>
  //       {({ TransitionProps }) => (
  //         <Grow {...TransitionProps}>
  //           <Paper elevation={5}
  //             style={{
  //               position: 'relative',
  //               '&[data-popper-placement*="bottom"] .arrow': {
  //                 top: 0,
  //                 left: 0,
  //                 marginTop: '-0.9em',
  //                 width: '3em',
  //                 height: '1em',
  //                 '&::before': {
  //                   borderWidth: '0 1em 1em 1em',
  //                   borderColor: `transparent transparent grey transparent`,
  //                 },
  //               },
  //               '&[data-popper-placement*="top"] .arrow': {
  //                 bottom: 0,
  //                 left: 0,
  //                 marginBottom: '-0.9em',
  //                 width: '3em',
  //                 height: '1em',
  //                 '&::before': {
  //                   borderWidth: '1em 1em 0 1em',
  //                   borderColor: `grey transparent transparent transparent`,
  //                 },
  //               },
  //               '&[data-popper-placement*="right"] .arrow': {
  //                 left: 0,
  //                 marginLeft: '-0.9em',
  //                 height: '3em',
  //                 width: '1em',
  //                 '&::before': {
  //                   borderWidth: '1em 1em 1em 0',
  //                   borderColor: `transparent grey transparent transparent`,
  //                 },
  //               },
  //               '&[data-popper-placement*="left"] .arrow': {
  //                 right: 0,
  //                 marginRight: '-0.9em',
  //                 height: '3em',
  //                 width: '1em',
  //                 '&::before': {
  //                   borderWidth: '1em 0 1em 1em',
  //                   borderColor: `transparent transparent transparent grey`,
  //                 },
  //               },
  //             }}
  //           >
  //             {console.log("Arrow ref:", arrowRef)}
  //             <span ref={setArrowRef} style={{
  //               position: 'absolute',
  //               fontSize: 7,
  //               width: '3em',
  //               height: '3em',
  //               '&::before': {
  //                 content: '""',
  //                 margin: 'auto',
  //                 display: 'block',
  //                 width: 0,
  //                 height: 0,
  //                 borderStyle: 'solid',
  //               },
  //             }} />
  //             <ClickAwayListener
  //               onClickAway={() => {
  //                 console.log("ClickAwayListener activated");
  //                 handleCloseMenu();
  //               }}>

  //               <Box
  //                 sx={{
  //                   paddingTop: '8px',
  //                   paddingBottom: '8px',
  //                   width: "350px",
  //                   borderRadius: '12px',
  //                 }}
  //               >
  //                 <Box
  //                   sx={{
  //                     display: 'flex',
  //                     flexDirection: 'row',
  //                     justifyContent: 'space-between',
  //                     alignItems: 'center',
  //                     paddingLeft: '8px',
  //                     paddingRight: '6px',
  //                   }}
  //                 >
  //                   <Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: 1 }}>
  //                     <Typography variant="body1">{formatNumberforMenuHeader(selectedItem.text)}</Typography>
  //                     {/* {selectedItem.referenceLinks && (selectedItem.referenceLinks.verified || selectedItem.referenceLinks.errorType) && ( */}
  //                     <>
  //                       <AutoAwesomeIcon sx={{ color: formattedErrorType().color }} fontSize="small" />
  //                       <Typography variant="body2" color={formattedErrorType().color} align="left">{formattedErrorType().message}</Typography>
  //                     </>
  //                     {/* )} */}
  //                   </Box>


  //                   <Box
  //                     sx={{
  //                       display: 'flex',
  //                       flexDirection: 'row',
  //                       gap: '1px',
  //                     }}
  //                   >
  //                     <Tooltip title={<><span>Add comment</span><br />(hotkey: c)</>} placement="top">
  //                       <IconButton aria-label="Add comment" onClick={handleAddComment} size="small"><AddCommentOutlinedIcon /></IconButton>
  //                     </Tooltip>
  //                     <Tooltip title={<><span>Add alert mark</span><br />(hotkey: x)</>} placement="top">
  //                       <IconButton aria-label="Please review" onClick={handleReview} size="small"><PriorityHighIcon /></IconButton>
  //                     </Tooltip>

  //                     <Tooltip title={<><span>Add tick mark</span><br />(hotkey: enter, space)</>} placement="top">
  //                       <IconButton aria-label="Checked" onClick={handleChecked} size="small">
  //                         <DoneIcon sx={{ fontWeight: 'bold' }} />
  //                       </IconButton>
  //                     </Tooltip>

  //                   </Box>
  //                 </Box>
  //                 {isCommentMode ? (
  //                   <>
  //                     <Divider sx={{ my: 1 }} />
  //                     {renderComments(comments, selectedItem)}
  //                     <Box p="8px">
  //                       <MKInput
  //                         variant="outlined"
  //                         placeholder="Add comment..."
  //                         multiline
  //                         fullWidth
  //                         autoFocus
  //                         rows={4}
  //                         value={currentComment}
  //                         onChange={(e) => setCurrentComment(e.target.value)}
  //                         onKeyDown={commentInputKeyDownHandler}
  //                         onKeyPress={(e) => {
  //                           if (e.key === 'Enter' && !e.shiftKey) {
  //                             e.preventDefault();
  //                             handleAddCommentAction();
  //                           }
  //                         }}
  //                         sx={{
  //                           '& .MuiOutlinedInput-root': {
  //                             '& fieldset': {
  //                               borderColor: 'primary.main',
  //                             },
  //                             '&:hover fieldset': {
  //                               borderColor: 'primary.main',
  //                             },
  //                             '&.Mui-focused fieldset': {
  //                               borderColor: 'primary.main',
  //                             },
  //                           },
  //                         }}
  //                       />
  //                       <MKButton onClick={handleAddCommentAction} variant="outlined" color="secondary" sx={{ mt: 2 }}>Add</MKButton>
  //                     </Box>
  //                   </>
  //                 ) : (
  //                   <>
  //                     {combinedReferencesAndAiText(selectedItem)}
  //                     {renderComments(comments, selectedItem)}
  //                   </>
  //                 )}
  //               </Box>
  //             </ClickAwayListener>
  //           </Paper>
  //         </Grow>
  //       )}
  //     </Popper>
  //   );
  // };

  useEffect(() => {
    const handleVisibilityChange = (entries) => {
      const [entry] = entries;
      //console.log("change is visible: ", entry)
      setIsReferenceVisible(entry.intersectionRatio === 0 ? false : true);
    };

    const observer = new IntersectionObserver(handleVisibilityChange, {
      root: null,
      rootMargin: '0px',
      threshold: 1, // Adjust the threshold as needed
    });

    if (anchorEl) {
      observer.observe(anchorEl);
    }

    return () => {
      if (anchorEl) {
        observer.unobserve(anchorEl);
      }
    };
  }, [anchorEl]);

  useEffect(() => {
    if (!isReferenceVisible) {
      if (ignoreCloseMenu) ignoreCloseMenu = false;
      else handleCloseMenu();
    }
  }, [isReferenceVisible]);

  const renderMenu = () => {

    if (!openMenu || selectedItem.pageIndex === -1) 
      {
      // console.log("renderMenu called with openMenu:", openMenu, "and selectedItem.pageIndex:", selectedItem.pageIndex);

      // console.log("Menu not rendered");
      return null;
    }
    // console.log("Rendering menu");

    return (
      <Popper
        key={selectedItem.id}
        open={openMenu}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal={false}
        arrow={true}
        transition
        style={{
          zIndex: 2,
          visibility: isShiftPressed ? 'hidden' : 'visible' // Hide the Popper when Shift key is pressed
        }}
        modifiers={[
          // {
          //   name: 'flip',
          //   enabled: true,
          //   options: {
          //     altBoundary: true,
          //     rootBoundary: 'document',
          //     padding: 20,
          //   },
          // },
          // {
          //   name: 'preventOverflow',
          //   enabled: true,
          //   options: {
          //     altAxis: true,
          //     altBoundary: true,
          //     tether: true,
          //     rootBoundary: 'document',
          //     padding: 20,
          //   },
          // },
          {
            name: 'offset',
            options: {
              offset: [0, 3],
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              elevation={10}
              style={{
                position: 'relative',
              }}
            >
              {/* {console.log("Arrow ref:", arrowRef)} */}
              <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
              <ClickAwayListener
                onClickAway={() => {
                  console.log("ClickAwayListener triggered");
                  handleCloseMenu();
                }}
              >
                <Box
                  sx={{
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    width: "360px",
                    borderRadius: '12px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      paddingLeft: '8px',
                      paddingRight: '6px',
                    }}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: 1 }}>
                      <Typography variant="body1">
                        {selectedItem.text.length > 8 ? "" : cleanText(selectedItem.text)}
                      </Typography>
                      <>
                        <AutoAwesomeIcon sx={{ color: formattedErrorType().color }} fontSize="small" />
                        <Typography variant="body2" color={formattedErrorType().color} align="left">
                          {formattedErrorType().message}
                        </Typography>
                      </>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1px',
                      }}
                    >
                      {
                        user.isAdmin ?
                        <Button aria-label="JSON" onClick={handleJSONButton} size="small">JSON</Button>
                        :
                        null
                      }
                      
                      <Tooltip title={<><span>Add comment</span><br />(hotkey: c)</>} placement="top">
                        <IconButton aria-label="Add comment" onClick={handleAddComment} size="small"><AddCommentOutlinedIcon /></IconButton>
                      </Tooltip>
                      <Tooltip title={<><span>Add alert mark</span><br />(hotkey: x)</>} placement="top">
                        <IconButton aria-label="Please review" onClick={handleReview} size="small"><PriorityHighIcon /></IconButton>
                      </Tooltip>

                      <Tooltip title={<><span>Add tick mark</span><br />(hotkey: enter, space)</>} placement="top">
                        <IconButton aria-label="Checked" onClick={handleChecked} size="small">
                          <DoneIcon sx={{ fontWeight: 'bold' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>

                  {isJSONMode ? (
                    <Box p="8px">

                      <AceEditor
                        mode="json"
                        theme="github"
                        name="code-editor"
                        value={currentJSON}
                        onChange={(newValue) => setCurrentJSON(newValue)}
                        fontSize={12}
                        width="100%"
                        height="400px"
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                          showLineNumbers: true,
                          tabSize: 2,
                          useWorker: false,
                        }}
                        onKeyDown={(e) => {
                          commentInputKeyDownHandler(e);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleAddJSONAction();
                          }
                        }}
                      />
                      <MKButton onClick={handleAddJSONAction} variant="outlined" color="primary" size="small" sx={{ mt: 2 }}>Save</MKButton>

                    </Box>
                  ) : (
                    <>
                      {isCommentMode ? (
                        <>
                          <Divider sx={{ my: 1 }} />
                          {renderComments(comments, selectedItem)}
                          <Box p="8px">
                            <MKInput
                              variant="outlined"
                              placeholder="Add comment..."
                              multiline
                              fullWidth
                              autoFocus
                              rows={4}
                              value={currentComment}
                              onChange={(e) => setCurrentComment(e.target.value)}
                              onKeyDown={commentInputKeyDownHandler}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                  e.preventDefault();
                                  handleAddCommentAction();
                                }
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: 'primary.main',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: 'primary.main',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: 'primary.main',
                                  },
                                },
                              }}
                            />
                            <MKButton onClick={handleAddCommentAction} variant="outlined" color="secondary" sx={{ mt: 2 }}>Add</MKButton>
                          </Box>
                        </>
                      ) : (
                        <>
                          {combinedReferencesAndAiText(selectedItem)}
                          {renderComments(comments, selectedItem)}
                        </>
                      )}
                    </>
                  )}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };


  const { activateTab } = defaultLayoutPluginInstance;


  const SidebarCommentMenuItem = ({ commentObj, onClick }) => {
    return (
      <Stack key={commentObj.id} spacing={1} sx={{
        width: '100%', cursor: 'pointer', '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.03)'
        }
      }} onClick={onClick} >
        <Tooltip title="" placement="right">
          <Grid container spacing={1} alignItems="center" sx={{ width: '100%' }}>
            <Grid item xs={1.5}>
              <CommentIcon sx={{ color: "grey.600" }} />
            </Grid>
            <Grid item xs={9} noWrap>
              <Typography variant="body3" sx={{ color: "grey.600", fontStyle: 'italic' }}>
                <Box component="blockquote" sx={{
                  paddingLeft: '0.5em',
                  mb: 0.5,
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '10%',
                    bottom: '10%',
                    left: 0,
                    borderLeft: (theme) => `2px solid ${theme.palette.grey[400]}`,
                  }
                }}>
                  {commentObj.highlightedText}
                </Box>
              </Typography>
              <Typography variant="body2" sx={{ mb: -0.50 }}>
                {commentObj.commentText}
              </Typography>
              <Typography variant="caption" sx={{ color: 'rgba(0,0,0,0.6)', marginRight: "4px" }}>
                {commentObj.user.firstName}
              </Typography>
              <Typography variant="caption" sx={{ color: 'rgba(0,0,0,0.4)' }}>
                {formatDate(commentObj.date)}
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography variant="body2" textAlign="center" sx={{ color: 'rgba(0,0,0,0.4)' }}>
                p.{commentObj.highlightDetails.pageIndex + 1}
              </Typography>
            </Grid>
          </Grid>
        </Tooltip>
        <Divider />
      </Stack>
    );
  };

  const sidebarComments = () => {
    const componentMap = {
      comment: SidebarCommentMenuItem,
      highPriority: SidebarInteractionMenuItem,
    };

    // Filter comments for specific interaction types
    const filteredComments = comments.filter(comment =>
      comment.interactionType === "comment" || comment.interactionType === "highPriority"
    );

    return (
      <div style={{ overflow: 'auto', width: '100%' }}>
        <Stack spacing={0} sx={{ pt: 0 }}>
          {filteredComments.length > 0 ? (
            filteredComments.map((commentObj, index) => {
              const Component = componentMap[commentObj.interactionType];
              return Component && (
                <Component
                  key={index}
                  commentObj={commentObj}
                  onClick={() => {
                    if (commentObj.newlyCreatedArea) {
                      console.log("Handling new area");
                    } else {
                      setSelectedItemHandler(commentObj.highlightDetails);
                    }
                  }}
                />
              );
            })
          ) : (
            <Stack sx={{ width: '100%' }}>
              <Typography variant="body2" color="rgba(0,0,0,0.6)" sx={{ mt: 2, textAlign: 'center' }}>
                No comments or alerts
              </Typography>
            </Stack>
          )}
        </Stack>
      </div>
    );
  }

  const getInteractionProps = (commentObj) => {
    const { interactionType } = commentObj;

    switch (interactionType) {
      case 'checked':
        return {
          icon: DoneIcon,
          style: { color: "grey.600" },
          text: commentObj.highlightedText,
          secondaryText: `${commentObj.user.firstName}`,
          tertiaryText: `${formatDate(commentObj.date)}`,
          tooltipText: "",
        };
      case 'highPriority':
        return {
          icon: PriorityHighIcon,
          style: { color: "grey.600" },
          text: commentObj.highlightedText,
          secondaryText: `${commentObj.user.firstName}`,
          tertiaryText: `${formatDate(commentObj.date)}`,
          tooltipText: "",
        };
      case 'comment':
        return {
          icon: CommentIcon,
          style: { color: "grey.600" },
          text: commentObj.commentText,
          secondaryText: `${commentObj.user.firstName}`,
          tertiaryText: `${formatDate(commentObj.date)}`,
          tooltipText: `${commentObj.highlightedText}: ${commentObj.commentText}`,
        };
      // Default case for unknown interaction types
      default:
        return {
          icon: CommentIcon,
          style: { color: "grey.600" },
          text: commentObj.commentText,
          secondaryText: `${commentObj.user.firstName}`,
          tertiaryText: `${formatDate(commentObj.date)}`,
          tooltipText: `${commentObj.highlightedText}: ${commentObj.commentText}`,
        };
    }
  };

  const SidebarInteractionMenuItem = ({ commentObj, handleCommentClick }) => {
    const { icon: Icon, style, text, secondaryText, tertiaryText, tooltipText } = getInteractionProps(commentObj);

    return (
      <Stack key={commentObj.id} spacing={0.5} sx={{
        width: '100%', cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
        }
      }} onClick={handleCommentClick}>
        <Tooltip title={tooltipText} placement="right">
          <Grid container spacing={1} alignItems="center" sx={{ width: '100%' }}>
            <Grid item xs={1.5}>
              <Icon sx={style} />
            </Grid>
            <Grid item xs={9} noWrap>
              <Typography variant="body2" sx={{ mb: -0.5 }} noWrap>
                {text}
              </Typography>
              <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.8)", marginRight: "4px" }}>
                {secondaryText}
              </Typography>
              <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.6)" }}>
                {tertiaryText}
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Typography variant="body2" textAlign="center" sx={{ color: 'rgba(0,0,0,0.4)' }} >
                p.{commentObj.highlightDetails.pageIndex + 1}
              </Typography>
            </Grid>
          </Grid>
        </Tooltip>
        <Divider variant="middle" />
      </Stack>
    );
  };

  const sidebarInteractions = (
    <div style={{ overflow: 'auto', width: '100%' }}>
      <Stack spacing={0} sx={{ pt: 0 }}>
        {comments.length > 0 ? (
          comments.map((commentObj, index) => (
            <SidebarInteractionMenuItem
              key={index}
              commentObj={commentObj}
              handleCommentClick={() => {
                if (commentObj.newlyCreatedArea) {
                  console.log("New area logic here");
                } else {
                  setSelectedItemHandler(commentObj.highlightDetails);
                }
              }}
            />
          ))
        ) : (
          <Stack sx={{ width: '100%' }}>
            <Typography variant="body2" color="rgba(0,0,0,0.6)" sx={{ mt: 2, textAlign: 'center' }}>
              No interactions
            </Typography>
          </Stack>
        )}
      </Stack>
    </div>
  );

  const SidebarErrorMenuItem = ({ highlight, handleClick }) => {

    return (
      <Stack key={highlight.id} spacing={0.5} sx={{ width: '100%', cursor: 'pointer', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.03)' } }} onClick={handleClick}>
        <Grid container spacing={1} alignItems="center" sx={{ width: '100%' }}>
          <Grid item xs={1.5}>
            <PriorityHighIcon />
          </Grid>
          <Grid item xs={8.5} noWrap>
            <Typography variant="body2" sx={{ mb: -0.5 }} noWrap>
              {highlight.referenceLinks?.value}
            </Typography>
            <Typography variant="caption" sx={{ color: "rgba(0,0,0,0.8)", overflow: "hidden", textOverflow: "ellipsis", marginRight: "4px" }}>
              {highlight.referenceLinks?.recommendedChange || highlight.referenceLinks?.value}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body3" textAlign="center" sx={{ color: 'rgba(0,0,0,0.4)' }} >
              p.{highlight.pageIndex + 1}
            </Typography>
          </Grid>
        </Grid>
        <Divider variant="middle" />
      </Stack>
    );
  };

  // // const commentMenuId="f74418e2-f80d-47c9-ae1b-be15a44ef419"

  const isTypoOrCalculationError = (referenceLinks) => {
    return referenceLinks && 
           (referenceLinks.errorType === "calculationError" || 
            referenceLinks.errorType === "typoError" || 
            referenceLinks.errorType === "typo");
  };

  const sidebarErrorList = () => {
    return (
      <div style={{ overflow: 'auto', width: '100%' }}>
        <Stack spacing={0} sx={{ pt: 0 }}>
          {formated_highlights.filter(item => isTypoOrCalculationError(item.referenceLinks)).length > 0 ? (
            formated_highlights.filter(item => isTypoOrCalculationError(item.referenceLinks)).map((highlight, index) => 
            (
              <SidebarErrorMenuItem
                key={index}
                highlight={highlight}
                handleClick={() => {
                  console.log("Highlight item selected:", highlight);
                  // Assuming openSideBarItem is a function to handle selection
                  setSelectedItemHandler(highlight);
                }}
              />
            ))
          ) : (
            <Stack sx={{ width: '100%' }}>
              <Typography variant="body1" color="rgba(0,0,0,0.6)" sx={{ mt: 2, textAlign: 'center' }}>
                No alerts found
              </Typography>
            </Stack>
          )}
        </Stack>
      </div>
    );
  };

  const getHighlightFormatting = (area) => {
    const hasAiReasoning = area.referenceLinks && area.referenceLinks.reasoning;
    const isTypoOrCalculationError = area.referenceLinks && (area.referenceLinks.errorType === "calculationError" || area.referenceLinks.errorType === "typoError" || area.referenceLinks.errorType === "typo");
    // const isVisited = visitedHighlightIds.includes(area.id);
    // const borderBottom = hasAiReasoning ? '3px dashed #a020f0' : 'none';
    const borderBottom = isTypoOrCalculationError ? '3px dashed rgba(217, 45, 32, 0.8)' : 'none';


    const getOpacity = () => area.id === selectedItem.id && openMenu ? '0.40' : '0.2';

    const getMostRecentInteractionType = () => {
      const relatedComments = comments.filter(comment => comment.highlightDetails.id === area.id);
      relatedComments.sort((a, b) => new Date(b.date) - new Date(a.date));
      return relatedComments.length > 0 ? relatedComments[0].interactionType : null;
    };

    const interactionType = getMostRecentInteractionType();

    // Determine formatting based on interaction type or category
    const determineFormatting = () => {
      if (interactionType === 'checked') {
        return { background: `rgba(18, 183, 106, ${getOpacity()})`, borderBottom: borderBottom };
      } else if (interactionType === 'comment' || interactionType === 'highPriority') {
        return { background: `rgba(247, 164, 9, ${getOpacity()})`, borderBottom: borderBottom };
      } else {
        switch (area.category) {
          case 'relatedReferences':
            return { background: `rgba(18, 183, 106, ${getOpacity()})`, borderBottom };
          case 'isVerified':
            return { background: `rgba(18, 183, 106, ${getOpacity()})`, borderBottom };
          case 'noRelatedReferences':
            return { background: `rgba(247, 164, 9, ${getOpacity()})`, borderBottom };
          default:
            return { background: 'rgba(240, 240, 240, 0.1)', borderBottom: "3px dashed blue" };
        }
      }
    };

    return determineFormatting();
  };


  const getIconVisibility = (area) => {
    // Filter comments to find those related to the given area.id
    const relatedComments = comments.filter(comment => comment.highlightDetails.id === area.id);

    // Sort these comments by date in descending order to get the most recent interaction first
    relatedComments.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Check the most recent interaction if there is any
    if (relatedComments.length > 0) {
      const mostRecentInteraction = relatedComments[0].interactionType;

      switch (mostRecentInteraction) {
        case 'checked':
          return {
            display: '', // Visible
            style: { color: "green", opacity: 0.8 },
            marginLeft: 'calc(100% - 4px)',
            marginTop: '-10px',
            icon: CheckCircleIcon,
          };
        case 'highPriority':
          return {
            display: '',
            marginLeft: 'calc(100% - 4px)',
            marginTop: '-10px',
            style: { color: "rgba(217, 45, 32, 0.8)" },
            icon: ErrorIcon,
          };
        case 'comment':
          return {
            display: '',
            marginLeft: 'calc(100% - 4px)',
            marginTop: '-10px',
            style: { color: "rgba(200, 128, 0, 0.9)" },
            icon: CommentIcon,
          };
        default:
          break;
      }
    }

    // If no relevant comments or interaction types were found
    return {
      display: 'none',  // Hide the icon if no matching conditions are met
    };
  };


  // const renderHighlightsOLD = (props) => {
  //   return (
  //     <div>
  //       {formated_highlights
  //         .filter((area) => area.pageIndex === props.pageIndex) // General filter for pageIndex
  //         .map((area, idx) => {
  //           const formatting = getHighlightFormatting(area);
  //           const iconVisibility = getIconVisibility(area);
  //           const { display, style: iconStyle, icon: IconComponent, marginLeft, marginTop } = iconVisibility;

  //           if (!highlightRefs.current[area.id]) {
  //             highlightRefs.current[area.id] = React.createRef();
  //           }

  //           const isSelected = highlightedItem === area.id && openMenu;
  //           const shouldHide = !isSelected && area.tableIndex === -1;

  //           if (isSelected || shouldHide) {

  //             // console.log("Rendering highlight for area:", area.id);
  //             // console.log("isSelected:", isSelected);
  //             // console.log("openMenu:", openMenu);
  //             // console.log("shouldHide:", shouldHide);

  //             const areaClass = isSelected ? "reference-highlights" : "hide-area";
  //             return (
  //               <div
  //                 key={idx}
  //                 ref={highlightRefs.current[area.id]}
  //                 className={`highlight-area ${areaClass}`}
  //                 data-area-id={area.id}
  //                 onClick={() => {
  //                   console.log("Clicked on area:", area);
  //                   setSelectedItemHandler(area)
  //                 }
  //                 }
  //                 style={Object.assign(
  //                   {},
  //                   isSelected
  //                     ? {
  //                       zIndex: 10000,
  //                       background: 'rgba(204, 255, 0, 0.2)', // Fluoro green/yellow
  //                       border: "1px solid #ccff00",
  //                     }
  //                     : {
  //                       zIndex: 10000,
  //                       background: formatting.background,
  //                       borderBottom: formatting.borderBottom,
  //                       opacity: 1,
  //                       cursor: 'pointer',
  //                       paddingBottom: "1px",
  //                       boxSizing: "content-box",
  //                     },
  //                   props.getCssProperties(area, props.rotation)
  //                 )}
  //               >
  //                 {areaClass === "hide-area" && (
  //                   <div
  //                     className="icon-wrapper"
  //                     style={{
  //                       marginLeft,
  //                       marginTop,
  //                       display,
  //                       ...iconStyle,
  //                     }}
  //                   >
  //                     {display !== 'none' && <IconComponent sx={{ ...iconStyle }} />}
  //                   </div>
  //                 )}
  //               </div>
  //             );
  //           }
  //           // If it's neither selected nor meets the hide condition, it will not render
  //           return null;
  //         })}
  //     </div>
  //   );
  // };


  const renderHighlights = (props) => {
    return (
      <div>
        {formated_highlights
          .filter((area) => area.pageIndex === props.pageIndex) // General filter for pageIndex
          .map((area, idx) => {
            const formatting = getHighlightFormatting(area);
            const iconVisibility = getIconVisibility(area);
            const { display, style: iconStyle, icon: IconComponent, marginLeft, marginTop } = iconVisibility;
  
            if (!highlightRefs.current[area.id]) {
              highlightRefs.current[area.id] = React.createRef();
            }
  
            const isSelected = highlightedItem === area.id && openMenu;
            const isException = highlightedItem === area.id && highlightedItem === selectedItem.id && openMenu;
            const shouldHide = !isSelected && (area.tableIndex === -1 || area.referenceLinks);

            if (isSelected || shouldHide || user.isAdmin) {
              const areaClass = isSelected ? "reference-highlights" : "hide-area";
              return (
                <div
                  key={idx}
                  ref={highlightRefs.current[area.id]}
                  className={`highlight-area ${areaClass}`}
                  data-area-id={area.id}
                  onClick={() => {
                    console.log("Clicked on area:", area);
                    setSelectedItemHandler(area)
                  }}
                  style={Object.assign(
                    {},
                    isException
                      ? {
                        zIndex: 10000,
                        background: 'rgba(247, 164, 9, 0.2)', // same color as unrelated references
                      }
                      : isSelected
                      ? {
                        zIndex: 10000,
                        background: 'rgba(204, 255, 0, 0.2)', // Fluoro green/yellow
                        border: "1px solid #ccff00",
                      }
                      : {
                        zIndex: 10000,
                        background: formatting.background,
                        borderBottom: formatting.borderBottom,
                        opacity: 1,
                        cursor: 'pointer',
                        paddingBottom: "1px",
                        boxSizing: "content-box",
                      },
                    props.getCssProperties(area, props.rotation)
                  )}
                >
                  {areaClass === "hide-area" && (
                    <div
                      className="icon-wrapper"
                      style={{
                        marginLeft,
                        marginTop,
                        display,
                        ...iconStyle,
                      }}
                    >
                      {display !== 'none' && <IconComponent sx={{ ...iconStyle }} />}
                    </div>
                  )}
                </div>
              );
            }
            // If it's neither selected nor meets the hide condition, it will not render
            return null;
          })}
      </div>
    );
  };



  //     return (
  //       <>
  //         <div>
  //           {formated_highlights
  //             .filter((area) => area.pageIndex === props.pageIndex&& area.tableIndex === -1)
  //             .map((area, idx) => {

  //               const formatting = getHighlightFormatting(area);

  //               const iconVisibility = getIconVisibility(area);
  //               // Destructure to get the icon component and additional styles if the area is visible
  //               const { display, style: iconStyle, icon: IconComponent, marginLeft, marginTop } = iconVisibility;

  //               if (!highlightRefs.current[area.id]) {
  //                 highlightRefs.current[area.id] = React.createRef();
  //               }

  //               return highlightedItem === area.id ? (
  //                 <div
  //                   key={idx}
  //                   ref={highlightRefs.current[area.id]} 
  //                   className="highlight-area reference-highlights"
  //                   onClick={() => setSelectedItemHandler(area)}
  //                   style={Object.assign(
  //                     {},
  //                     {
  //                       zIndex: 10000,
  //                       background: 'orange',
  //                       opacity: 0.4,
  //                     },
  //                     props.getCssProperties(area, props.rotation)
  //                   )}
  //                 />
  //               ) : (
  //                 <div
  //                   key={idx}
  //                   ref={highlightRefs.current[area.id]} 
  //                   className="highlight-area hide-area"
  //                   data-area-id={area.id} 
  //                   onClick={() => setSelectedItemHandler(area)}
  //                   style={Object.assign(
  //                     {},
  //                     {
  //                       zIndex: 10000,
  //                       background: formatting.background,
  //                       borderBottom: formatting.borderBottom,
  //                       opacity: 1,
  //                       cursor: 'pointer',
  //                       // marginTop: "-0.1%",
  //                       // marginLeft: "-0.1vw",
  //                       // paddingBottom: "0.1%",
  //                       // paddingLeft: "0.2vw",
  //                       boxSizing: "content-box",
  //                     },
  //                     props.getCssProperties(area, props.rotation)
  //                   )}
  //                 >
  //                   <div
  //                     className="icon-wrapper"
  //                     style={{
  //                       marginLeft,
  //                       marginTop,
  //                       display, // Applying the display style from getIconVisibility
  //                       ...iconStyle, // Applying additional styles specific to the icon type
  //                     }}
  //                   >
  //                     {/* Conditionally render the icon based on visibility */}
  //                     {display !== 'none' && <IconComponent sx={{ ...iconStyle }} />}
  //                   </div>
  //                 </div>
  //               );
  //             })}

  //           {/* Render the highlights associated with notes */}
  //           {comments
  //             .filter((comment) => comment.newlyCreatedArea && comment.highlightAreas.some(highlightArea => highlightArea.pageIndex === props.pageIndex))
  //             .map((comment, commentIndex) => (<React.Fragment key={`comment-${comment.interactionId}`}>
  //               {comment.highlightAreas
  //                 .filter((area) => area.pageIndex === props.pageIndex)
  //                 .map((area, areaIndex, highlightAreas) => {
  //                   // Determine if the current area is the last one in the array
  //                   const isLastHighlightArea = areaIndex === highlightAreas.length - 1;

  //                   return (
  //                     <div
  //                       className="highlight-area hide-area"
  //                       key={`comment-area-${areaIndex}`}
  //                       // data-area-id={comment.highlightId} 
  //                       // Only apply data-area-id to the last highlight area
  //                       data-area-id={isLastHighlightArea ? comment.highlightId : undefined}
  //                       style={Object.assign(
  //                         {},
  //                       {
  //                         zIndex: 10000,
  //                         background: "rgba(255, 255, 0, 0.3)",
  //                         opacity: 1,
  //                       },
  //                       props.getCssProperties(area, props.rotation)
  //                     )}
  //                   >
  //                     {/* Conditionally render the icon only for the last highlight area */}
  //                     {isLastHighlightArea && (
  //                       <div
  //                         style={{
  //                           position: 'absolute',
  //                           right: "-10px", // Adjust positioning as needed
  //                           top: '20px',
  //                           transform: 'translateY(-50%)',
  //                           color: "rgba(200, 128, 0, 0.6)" 
  //                         }}
  //                       >
  //                         <CommentIcon /> 
  //                       </div>
  //                     )}
  //                   </div>
  //                 );
  //               })}
  //           </React.Fragment>
  //         ))}
  //       </div>
  //     </>
  //   );
  // };

  // const renderHighlightsSecondView = (props) => {

  //   return (
  //     <>
  //       <div>
  //         {formated_highlights
  //           .filter((area) => area.pageIndex === props.pageIndex)
  //           .map((area, idx) => {

  //             const formatting = getHighlightFormatting(area);

  //             const iconVisibility = getIconVisibility(area);
  //             // Destructure to get the icon component and additional styles if the area is visible
  //             const { display, style: iconStyle, icon: IconComponent, marginLeft, marginTop } = iconVisibility;

  //             if (!highlightRefs.current[area.id]) {
  //               highlightRefs.current[area.id] = React.createRef();
  //             }

  //             return highlightedItem === area.id ? (
  //               <div
  //                 key={idx}
  //                 ref={highlightRefs.current[area.id]} 
  //                 className="highlight-area reference-highlights"
  //                 onClick={() => setSelectedItemHandler(area)}
  //                 style={Object.assign(
  //                   {},
  //                   {
  //                     zIndex: 10000,
  //                     background: 'orange',
  //                     opacity: 0.4,
  //                   },
  //                   props.getCssProperties(area, props.rotation)
  //                 )}
  //               />
  //             ) : (
  //               <div
  //                 key={idx}
  //                 ref={highlightRefs.current[area.id]} 
  //                 className="highlight-area hide-area"
  //                 data-area-id={area.id} 
  //                 onClick={() => setSelectedItemHandler(area)}
  //                 style={Object.assign(
  //                   {},
  //                   {
  //                     zIndex: 10000,
  //                     background: formatting.background,
  //                     borderBottom: formatting.borderBottom,
  //                     opacity: 1,
  //                     cursor: 'pointer',
  //                     /*marginTop: "-0.1%",
  //                     // marginLeft: "-0.1vw",
  //                     paddingBottom: "0.6%",
  //                     paddingLeft: "0.2vw",
  //                     boxSizing: "content-box",*/
  //                   },
  //                   props.getCssProperties(area, props.rotation)
  //                 )}
  //               >
  //                 <div
  //                   className="icon-wrapper"
  //                   style={{
  //                     marginLeft,
  //                     marginTop,
  //                     display, // Applying the display style from getIconVisibility
  //                     ...iconStyle, // Applying additional styles specific to the icon type
  //                   }}
  //                 >
  //                   {/* Conditionally render the icon based on visibility */}
  //                   {display !== 'none' && <IconComponent sx={{ ...iconStyle }} />}
  //                 </div>
  //               </div>
  //             );
  //           })}

  //         {/* Render the highlights associated with notes */}
  //         {comments
  //           .filter((comment) => comment.newlyCreatedArea && comment.highlightAreas.some(highlightArea => highlightArea.pageIndex === props.pageIndex))
  //           .map((comment, commentIndex) => (<React.Fragment key={`comment-${comment.interactionId}`}>
  //             {comment.highlightAreas
  //               .filter((area) => area.pageIndex === props.pageIndex)
  //               .map((area, areaIndex, highlightAreas) => {
  //                 // Determine if the current area is the last one in the array
  //                 const isLastHighlightArea = areaIndex === highlightAreas.length - 1;

  //                 return (
  //                   <div
  //                     className="highlight-area hide-area"
  //                     key={`comment-area-${areaIndex}`}
  //                     // data-area-id={comment.highlightId} 
  //                     // Only apply data-area-id to the last highlight area
  //                     data-area-id={isLastHighlightArea ? comment.highlightId : undefined}
  //                     style={Object.assign(
  //                       {},
  //                     {
  //                       zIndex: 10000,
  //                       background: "rgba(255, 255, 0, 0.3)",
  //                       opacity: 1,
  //                     },
  //                     props.getCssProperties(area, props.rotation)
  //                   )}
  //                 >
  //                   {/* Conditionally render the icon only for the last highlight area */}
  //                   {isLastHighlightArea && (
  //                     <div
  //                       style={{
  //                         position: 'absolute',
  //                         right: "-10px", // Adjust positioning as needed
  //                         top: '20px',
  //                         transform: 'translateY(-50%)',
  //                         color: "rgba(200, 128, 0, 0.6)" 
  //                       }}
  //                     >
  //                       <CommentIcon /> 
  //                     </div>
  //                   )}
  //                 </div>
  //               );
  //             })}
  //         </React.Fragment>
  //       ))}
  //     </div>
  //   </>
  // );
  // };

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    // renderHighlightTarget,
    // renderHighlightContent,
    trigger: Trigger.None,
  });

  const { jumpToHighlightArea } = highlightPluginInstance;

  const pageNavigationPluginInstance2 = pageNavigationPlugin();
  const jumpToPage2 = pageNavigationPluginInstance2.jumpToPage

  const pageNavigationPluginInstance1 = pageNavigationPlugin();
  const jumpToPage1 = pageNavigationPluginInstance1.jumpToPage;
  const useCurrentPage1 = pageNavigationPluginInstance1.useCurrentPage;


  const highlightPluginInstance2 = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const formattedErrorType = () => {
    if (selectedItem?.category === "relatedReferences" && !selectedItem?.referenceLinks) {
      return { message: "Direct match", color: "#027A48" };
    } else if (selectedItem?.referenceLinks?.verified) {
      return { message: "Reasoned match", color: "#027A48" };
    } else {
      const errorType = selectedItem?.referenceLinks?.errorType;
      const errorMap = {
        typo: { message: "Typo error", color: "#B42318" },
        calculationError: { message: "Calculation error", color: "#B42318" },
        noReference: { message: "Missing reference", color: "#B42318" }
      };
      return errorMap[errorType] || { message: "Error", color: "#B42318" };
    }
  };


  const combinedReferencesAndAiText = (selectedItem) => {
    //console.log("Initial selectedItem:", selectedItem);

    const hasReferences = selectedItem?.references?.length > 0;
    const relatedItems = hasReferences ? selectedItem.references.filter(item => item.match) : [];
    const unRelatedItems = hasReferences ? selectedItem.references.filter(item => !item.match) : [];
    const aiReferenceItems = selectedItem?.referenceLinks?.aiReferenceObjects?.filter(item => item != null) || [];

    // console.log("Has references:", hasReferences);
    // console.log("Number of related items:", relatedItems.length);
    // console.log("Number of unrelated items:", unRelatedItems.length);
    // console.log("Number of AI reference items:", aiReferenceItems.length);

    const handleMenuItemAction = (index, section, reference) => {
      //console.log("action for: ", reference);
      //console.log("Handling action for section:", section, "with index:", index);

      setHighlightedItem(reference.id);
      // jumpToPage2(formated_highlights.find(highlight => highlight.id === reference.id).pageIndex);

      jumpToHighlightArea2(formated_highlights.find(highlight => highlight.id === reference.id));

      // 

      let unifiedIndex = index;
      if (section === "related") {
        unifiedIndex = index;
      } else if (section === "aiReference") {
        unifiedIndex = relatedItems.length + index;
      } else if (section === "unrelated") {
        unifiedIndex = relatedItems.length + aiReferenceItems.length + index;
      }

      //console.log("Calculated unified index:", unifiedIndex);
      //console.log("Setting selected index:", unifiedIndex);

      setSelectedIndex(unifiedIndex);
    };

    const cleanReasonRegex = (reason) => {
      const cleaned = reason.replace(/^Table cell shows\s+/, '').replace(/^Table cell\s+/, '').replace(/^[.]/, '');
      return cleaned.charAt(0).toUpperCase() + cleaned.slice(1);
    };

    return (
      <>
      <StyledViewer>
        <Box sx={{ maxHeight: '24em', overflowY: 'auto' }}>
          <MenuList dense>
            {/* Related References Section */}
            {relatedItems.length > 0 && (
              <>
                <Divider component="li" textAlign="center">
                  <Chip label={`${relatedItems.length} related`} size="small"
                    sx={{
                      backgroundColor: '#00000000', opacity: '0.7',
                      '.MuiChip-label': {
                        fontSize: '0.75rem',
                        fontWeight: 300
                      }
                    }}
                  />
                </Divider>
                {relatedItems.length > 0 ? (
                  relatedItems.map((reference, idx) => (
                    <MenuItem
                      key={`related-${idx}`}
                      selected={selectedIndex === idx}
                      autoFocus={idx === 0 && relatedItems.length > 0}
                      onClick={() => handleMenuItemAction(idx, "related", reference)}
                      onFocus={() => handleMenuItemAction(idx, "related", reference)}
                      sx={{
                        "&:focus, &.Mui-selected, &.Mui-selected:focus": {
                          backgroundColor: "rgba(18, 183, 106, 0.08)"
                        },
                        "&.Mui-selected:hover": { backgroundColor: "rgba(18, 183, 106, 0.12)" } // green, slightly darker on hover
                      }}
                    >
                      <ListItemIcon>
                        <Typography variant="body3">p.{reference?.page_num}</Typography>
                      </ListItemIcon>
                      <Tooltip title={reference.reason} placement="right" disableInteractive>
                        <Box flexGrow={1}>
                          <Typography variant="body2" display="block" sx={{ width: "282px" }} noWrap>{cleanReasonRegex(reference.reason)}</Typography>
                        </Box>
                      </Tooltip>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>
                    <Typography variant="body2">No related references</Typography>
                  </MenuItem>
                )}
              </>
            )}

            {/* AI Suggestions Divider and Text */}
            {selectedItem.referenceLinks && (
              <>
                <Divider component="li" textAlign="center">
                  <Chip label="AI suggestion" size="small"
                    sx={{
                      backgroundColor: '#00000000', opacity: '0.7',
                      '.MuiChip-label': {
                        fontSize: '0.75rem',
                        fontWeight: 300
                      }
                    }}
                  />
                </Divider>

                <Box sx={{ pl: 1, pr: 1, mb: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", gap: 0.5 }}>
                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ gap: 1, flexGrow: 1 }} >
                    {/* <Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: 1 }} >
                      <AutoAwesomeIcon />
                      <Typography variant="body2" color={formattedErrorType().color} align="left">{formattedErrorType().message}</Typography>
                    </Box> */}
                    {selectedItem.referenceLinks && (
                      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" sx={{ gap: 1, width: "100%" }} >
                        {/* <TipsAndUpdatesIcon color="disabled" fontsize="small" /> */}
                        <Tooltip title="Recommended value" placement="right">
                          <Typography variant="body2" align="left" sx={{
                            // fontFamily: 'Roboto Mono, "Courier Prime", monospace', fontSize: "16px",
                            maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",
                            border: "1px solid rgba(0, 0, 0, 0.3)", // purple colour: rgba(158, 119, 237, 0.8), grey rgba(0, 0, 0, 0.3)
                            borderRadius: "8px", padding: "0px 8px"
                          }}>
                            {selectedItem.referenceLinks?.recommendedChange || selectedItem.referenceLinks?.value}
                          </Typography>
                        </Tooltip>

                        <Tooltip title="Accept AI comment" placement="right">

                          <IconButton
                            onClick={handleAddAiCommentAction}
                            size="small" sx={{
                              color: "rgba(0, 0, 0, 0.3)",
                              '&:hover': { backgroundColor: 'rgba(158, 119, 237, 0.14)', color: "rgba(158, 119, 237, 0.8)" }
                            }} // purple colour: rgba(158, 119, 237, 0.8)
                          >
                            <AddCommentOutlinedIcon />
                          </IconButton>

                        </Tooltip>

                      </Box>
                    )}
                  </Box>
                  {isEditingMode ? (
                    <TextField
                      fullWidth
                      multiline
                      maxRows={6}        // Maximum number of rows the TextField can expand to
                      variant="standard"
                      value={aiReasoning}
                      onChange={(e) => setAiReasoning(e.target.value)} // Update reasoning text
                      onBlur={() => setIsEditingMode(false)} // Exit edit mode
                      autoFocus
                      InputProps={{
                        sx: {
                          color: 'text.primary', // Use theme color
                          fontSize: '14px',      // Match the Typography's body2 font size (typically around 1rem)
                          lineHeight: 1.6,      // Line height similar to Typography body2
                          alignItems: 'left'
                        }
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      align="left"
                      onClick={() => setIsEditingMode(true)} // Enter edit mode
                      sx={{ pt: "4px", pb: "4px", cursor: 'text', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                    >
                      {aiReasoning}
                    </Typography>
                  )}
                  {/* <Button variant="outlined" color="primary" size="small" sx={{ color: "#535353" }} onClick={handleAddAiCommentAction}>Accept as comment</Button> */}
                </Box>

                {aiReferenceItems && aiReferenceItems.length > 0 && aiReferenceItems.every(item => item && item.originalText) && (
                  aiReferenceItems.map((reference, idx) => (
                    <MenuItem
                      key={`ai-ref-${idx}`}
                      selected={selectedIndex === relatedItems.length + idx}
                      autoFocus={idx === 0 && aiReferenceItems.length > 0 && relatedItems.length === 0} // autofocus first AI reference item
                      onClick={() => handleMenuItemAction(idx, "aiReference", reference)}
                      onFocus={() => handleMenuItemAction(idx, "aiReference", reference)}
                      sx={{
                        "&:focus, &.Mui-selected, &.Mui-selected:focus": {
                          backgroundColor: "rgba(105, 65, 198, 0.08)"
                        },
                        "&.Mui-selected:hover": { backgroundColor: "#d3d3d385" } // purple, slightly darker on hover
                      }}
                    >
                      <ListItemIcon>
                        <Typography variant="body3">p.{reference?.page_num}</Typography>
                      </ListItemIcon>
                      <Box flexGrow={1}>
                        <Typography variant="body3" display="block" sx={{ width: "282px" }} noWrap>
                          {reference?.originalText}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))
                )}
              </>
            )}


            {/* Unrelated References Section */}
            <Divider component="li" textAlign="center">
              <Chip label={`${unRelatedItems.length} unrelated`} size="small"
                sx={{
                  backgroundColor: '#00000000', opacity: '0.7',
                  '.MuiChip-label': {
                    fontSize: '0.75rem',
                    fontWeight: 300
                  }
                }}
              />
            </Divider>
            {unRelatedItems.length > 0 ? (
              unRelatedItems.map((reference, idx) => (
                <MenuItem
                  key={`unrelated-${idx}`}
                  selected={selectedIndex === relatedItems.length + aiReferenceItems.length + idx}
                  autoFocus={idx === 0 && unRelatedItems.length > 0 && relatedItems.length === 0 && aiReferenceItems.length === 0}
                  onClick={() => handleMenuItemAction(idx, "unrelated", reference)}
                  onFocus={() => handleMenuItemAction(idx, "unrelated", reference)}
                  sx={{
                    "&:focus, &.Mui-selected, &.Mui-selected:focus": {
                      backgroundColor: "rgba(247, 164, 9, 0.08)"
                    },
                    "&.Mui-selected:hover": { backgroundColor: "rgba(247, 164, 9, 0.12)" } // orange, slightly darker on hover
                  }}
                >
                  <ListItemIcon>
                    <Typography variant="body3">p.{reference?.page_num}</Typography>
                  </ListItemIcon>
                  <Tooltip title={reference.reason} placement="right" disableInteractive>
                    <Box flexGrow={1} sx={{ width: "282px" }}>
                      <Typography variant="body3" display="block" sx={{ opacity: '0.7' }} noWrap>{cleanReasonRegex(reference.reason)}</Typography>
                    </Box>
                  </Tooltip>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                <Typography variant="body3" color="textSecondary">No unrelated references</Typography>
              </MenuItem>
            )}
          </MenuList>
        </Box>
        </StyledViewer>
      </>
    );
  };


  const jumpToHighlightArea1 = highlightPluginInstance.jumpToHighlightArea;

  const jumpToHighlightArea2 = highlightPluginInstance2.jumpToHighlightArea;

  const cycleStyleHandler = (event) => {
    setCycleStyle(event.target.value);

    // Attempt to blur the focused element directly if accessible
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const handleRadioGroupClick = (event) => {
    // Prevent click event from reaching the ClickAwayListener
    event.stopPropagation();
  };


  const getNewSidebar = (cycleStyle) => {

    // Function to handle accordion changes
    const handleAccordionChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    // Stop event propagation to prevent click away listener from firing
    const handlePropagation = (event) => {
      event.stopPropagation();
    };

    return (
      <>
        <Card
          sx={{
            p: 1,
            mr: { xs: 1, lg: 2 },
            ml: 0,
            mt: 0,
            mb: 0,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
          style={{
            width: "19em",
            height: "calc(100vh - 145px)",
            overflow: "auto"
          }}
        >
          <Box
            display="flex" justifyContent="flex-end"
          >
            <IconButton
              onClick={toggleSidebar}
              style={{ marginRight: 8 }}
              size="small"
            >
              {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="selection-tabs">
                <Tab label="Alerts" value="1" wrapped sx={{ fontSize: "14px", p: 1, pt: 0 }} />
                <Tab label="Comments" value="2" sx={{ fontSize: "14px", p: 1, pt: 0 }} />
              </TabList>
              {/* <IconButton
              onClick={toggleSidebar}
              style={{ marginRight: 8 }}
              size="small"
            >
              {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton> */}
            </Box>
            <Box
              sx={{
                overflow: 'auto',
                flexGrow: 1,
                mb: 2
              }}
            >
              <TabPanel value="1" sx={{ p: 0, flexGrow: 1, overflow: 'auto' }}>
                {/* {sidebarInteractions} */}
                {sidebarErrorList()}
              </TabPanel>
              <TabPanel value="2" sx={{ p: 0, flexGrow: 1, overflow: 'auto' }}>
                {sidebarComments()}
              </TabPanel>
            </Box>
            <Accordion onClick={handlePropagation} expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandLessIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Tooltip title="Use arrow keys to cycle through green, orange or all numbers" placement="top" disableInteractive>
                  <Typography variant="body2" fontWeight="normal" color="rgba(0,0,0,0.6)">
                    L/R keyboard shortcuts
                  </Typography>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails onClick={handlePropagation}>
                <FormControl component="fieldset">
                  {/* <FormLabel component="legend" id="cycle-style-radio-buttons-group">Choose cycle</FormLabel> */}
                  <RadioGroup
                    aria-labelledby="cycle-style-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    defaultValue="all"
                    value={cycleStyle}
                    onChange={cycleStyleHandler}
                  >
                    <FormControlLabel value="all" control={<Radio color="primary" />} label="All" />
                    <FormControlLabel value="orange" control={<Radio color="primary" />} label="Yellow only" />
                    <FormControlLabel value="green" control={<Radio color="primary" />} label="Green only" />
                  </RadioGroup>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </TabContext>
        </Card>
      </>
    );
  }


  React.useEffect(() => {
    console.log("cycle style:", cycleStyle);
  }, [cycleStyle]);

  // add collapsable sidebar stuff here. Assume all imports and states are already declared


  const renderPage = (props, oldSrcData, srcData, widthData, currentPageIndex, tempTImout, tempTImout2, isLeftPanel) => {
    const canvas = props.canvasLayer.children.props.canvasLayerRef.current;

    if (!oldSrcData[props.pageIndex]) {
      oldSrcData[props.pageIndex] = "old";
    }
    
    if (!srcData[props.pageIndex] || (props.pageIndex === currentPageIndex && widthData[props.pageIndex] !== props.width) /*|| widthDataLeftPanel[props.pageIndex] !== props.width*/) {
      if (canvas && canvas.parentElement && canvas.parentElement.parentElement) {
        const parentElement = canvas.parentElement.parentElement;
        //if (!parentElement.style.backgroundImage || (!canvas?.parentElement?.parentElement?.style.backgroundImage && !canvas?.parentElement?.parentElement?.style.backgroundImage.length < 10)) {
          

        if (tempTImout[props.pageIndex]) clearTimeout(tempTImout[props.pageIndex])
          tempTImout[props.pageIndex] = setTimeout(() => {
          
          srcData[props.pageIndex] = canvas.toDataURL(); //`url("${canvas.toDataURL()}")`;
          if (canvas.toDataURL().length > 25 && oldSrcData[props.pageIndex].length < srcData[props.pageIndex].length) {

            oldSrcData[props.pageIndex] = srcData[props.pageIndex];
            widthData[props.pageIndex] = props.width;
            if (tempTImout2[props.pageIndex]) clearTimeout(tempTImout2[props.pageIndex])
            parentElement.style.backgroundImage = `url("${srcData[props.pageIndex]}")`;//`url("${canvas.toDataURL()}")`;
          parentElement.style.backgroundSize = 'auto 100%'; // Stretches the image to cover the full width and height of the element
          parentElement.style.backgroundPosition = '0 0';

            if (!firstLoadDoneLeftPanel && props.pageIndex === currentPageLeftPanel) {
              firstLoadDoneLeftPanel = true;
            }

            if (!firstLoadDoneRightPanel && props.pageIndex === currentPageRightPanel) {
              firstLoadDoneRightPanel = true;
            }

            if (firstLoadDoneLeftPanel && firstLoadDoneRightPanel) {
              window.canLoadInternalConsistency = true;

              if (window.canLoadFactualAccuracy) {
                setTimeout(function () {
                  const mainCOmponent = document.querySelector(".loaded-main-component");
              if (mainCOmponent) mainCOmponent.style.opacity = "1";
                }, 580);
              }
            }
          } else if (canvas.toDataURL().length < 25 && oldSrcData[props.pageIndex].length < 25) {

            setTimeout(() => {

              if (canvas.toDataURL().length > 25) {
                
                if (tempTImout2[props.pageIndex]) clearTimeout(tempTImout2[props.pageIndex])

                srcData[props.pageIndex] = canvas.toDataURL(); //`url("${canvas.toDataURL()}")`;
                parentElement.style.backgroundImage = `url("${srcData[props.pageIndex]}")`;//`url("${canvas.toDataURL()}")`;
                parentElement.style.backgroundSize = 'auto 100%'; // Stretches the image to cover the full width and height of the element
                parentElement.style.backgroundPosition = '0 0';

                zoomTo(0.8);
                  setTimeout(() => {
                    zoomTo(SpecialZoomLevel.PageFit);
                  }, 1200)

                if (isLeftPanel && !firstLoadDoneLeftPanel && props.pageIndex === currentPageLeftPanel) {
                  firstLoadDoneLeftPanel = true;
                }
    
                if (!isLeftPanel && !firstLoadDoneRightPanel && props.pageIndex === currentPageRightPanel) {
                  firstLoadDoneRightPanel = true;
                }

                if (firstLoadDoneLeftPanel && firstLoadDoneRightPanel) {
                  window.canLoadInternalConsistency = true;

                  if (window.canLoadFactualAccuracy) {
                    setTimeout(function () {
                      const mainCOmponent = document.querySelector(".loaded-main-component");
                  if (mainCOmponent) mainCOmponent.style.opacity = "1";
                    }, 580);
                  }
                }
    
              
              } else if (props.pageIndex === currentPageLeftPanel) {
                if (tempTImout2[props.pageIndex]) clearTimeout(tempTImout2[props.pageIndex])
                  tempTImout2[props.pageIndex] = setTimeout(() => {

                  if ((!firstLoadDoneLeftPanel && isLeftPanel) || (!isLeftPanel && !firstLoadDoneRightPanel)) {
                    if (!firstLoadDoneLeftPanel && isLeftPanel) firstLoadDoneLeftPanel = true;
                    else firstLoadDoneRightPanel = true;
                    zoomTo(0.8);
                  setTimeout(() => {
                    zoomTo(SpecialZoomLevel.PageFit);
                    if (tempTImout2[props.pageIndex]) clearTimeout(tempTImout2[props.pageIndex])
                      if (tempTImout[props.pageIndex]) clearTimeout(tempTImout[props.pageIndex]);
                  }, 1200)
                  }
                  
                 }, 1000)
              }
              
            }, 2000)

          }
          
        
        }, 100)

        
      }
    }

    return (
    <>
        {
                canvas ?
                <div style={{opacity: 0, width: `${canvas.width}px`, height: `${canvas.height}px`, display: "none"}}></div>
                :
                null
              }
        {props.canvasLayer.children}

        {props.annotationLayer.children}
        {props.textLayer.children}
    </>
    );
  };

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const drawerWidth = "calc(18EM + 2REM"; // Set the width of the sidebar

  const handlePageChangeLeftPanel = (e) => {
    console.log("page changed: ",e.currentPage)
    setCurrentPageLeftPanel(e.currentPage);
};


const handlePageChangeRightPanel = (e) => {
  console.log("page changed: ",e.currentPage)
  setCurrentPageRightPanel(e.currentPage);
};
  return (
    <StyledViewer>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
      <PanelGroup autoSaveId="InternalConsistency" direction="horizontal" style={{ height: "calc(100vh/var(--zoom-level) - 145px)" }}>

        <Drawer
          variant="persistent"
          anchor="left"
          open={isSidebarOpen}
          style={{ width: isSidebarOpen ? drawerWidth : 0 }}
          PaperProps={{
            style: {
              background: "transparent",
              position: "relative",
              height: "calc(100vh - 145px)",
              overflow: "hidden",
              // transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
            }
          }}
        >
          {getNewSidebar(cycleStyle)}
        </Drawer>

        <Box display={isSidebarOpen ? 'none' : 'flex'} flexDirection="column" justifyContent="flex-start" >
          {/* <Tooltip title="Open sidebar"> */}
          <IconButton
            onClick={toggleSidebar}
            style={{ marginRight: 8 }}
            size="small"
          >
            {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {/* </Tooltip> */}
        </Box>
        
        {
          useNewRenderFix ?
          <Panel defaultSize={50} minSize={40}>
           <div className="internalConsistencyPanel"  style={{height: "100%"}}>
           <Viewer fileUrl={firstPdf}
           onPageChange={handlePageChangeLeftPanel}
           renderPage={(props) => renderPage(props, oldSrcDataLeftPanel, srcDataLeftPanel, widthDataLeftPanel, currentPageLeftPanel, tempTImoutPanel1, tempTImout2Panel1, true)}
           style={{ height: 'calc(100vh/var(--zoom-level) - 65px)', width: `${(window.innerWidth/2)-50}px` }}
            defaultScale={SpecialZoomLevel.PageFit}
            setRenderRange={setRenderRange1}
            pageLayout={pageLayout1}
            ref={viewerRef}
            onDocumentLoad={() => {
              tempTImoutPanel1 = {};
tempTImout2Panel1 = {};
srcDataLeftPanel = {};
widthDataLeftPanel = {};
oldSrcDataLeftPanel = {};
firstLoadDoneLeftPanel = false;
window.canLoadInternalConsistency = false;
            firstLoadDoneLeftPanel = false;
              if (useNewRenderFix) {
                jumpToPage1(62); setTimeout(() => jumpToPage1(0), 2000)
              }
              }}
            plugins={[defaultLayoutPluginInstance, highlightPluginInstance, pageNavigationPluginInstance1, zoomPluginInstance]} />
           </div>
          
        </Panel>
        :
        <Panel defaultSize={50} minSize={40}>
          <Viewer fileUrl={firstPdf}
            defaultScale={SpecialZoomLevel.PageWidth}
            pageLayout={pageLayout1}
            plugins={[defaultLayoutPluginInstance, highlightPluginInstance, pageNavigationPluginInstance1]} />
        </Panel>
        }
        
        <PanelResizeHandle className='resize-bar'
        />
        <Panel defaultSize={50} minSize={40} className='dont-show-popup'>
        <div className="internalConsistencyPanel"  style={{height: "100%"}}>
        <Viewer fileUrl={secondPdf}
        style={{ height: 'calc(100vh/var(--zoom-level) - 65px)', width: `${(window.innerWidth/2)-50}px` }}
            defaultScale={SpecialZoomLevel.PageFit}
            renderPage={(props) => renderPage(props, oldSrcDataLeftPanel2, srcDataLeftPanel2, widthDataLeftPanel2, currentPageRightPanel, tempTImoutPanel2, tempTImout2Panel2, false)}
            onPageChange={handlePageChangeRightPanel}
            setRenderRange={setRenderRange2}
            onDocumentLoad={(e) => {
              tempTImoutPanel2 = {};
tempTImout2Panel2 = {};
srcDataLeftPanel2 = {};
widthDataLeftPanel2 = {};
oldSrcDataLeftPanel2 = {};
firstLoadDoneRightPanel = false;
              if (useNewRenderFix) {
                jumpToPage2(62); setTimeout(() => jumpToPage2(0), 2000)
              }
            }}
            pageLayout={pageLayout2}
            plugins={[defaultLayoutPluginInstance2, highlightPluginInstance2, pageNavigationPluginInstance2]} />
        </div>
          
        </Panel>
        
      </PanelGroup>
      {renderMenu()}
      <FeedbackFab />
    </Worker>
    </StyledViewer>
  );
};

export default InternalConsistency;